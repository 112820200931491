import { useEffect } from "react";
import { Loading } from "../components/loading";
import { DwellirShieldTextIcon } from "../icons/dwellir-shield-text";
import { Flex, Paper, Text } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { useUser } from "src/store/user";
import { PageRoute } from "src/utility/utils";

export const VerifyPage = () => {
  const user = useUser();
  const navigate = useNavigate();

  useEffect(() => {
    if (user.state === "fulfilled" && user.data.isVerified) {
      navigate(PageRoute.INDEX);
    }
  }, [user, navigate]);

  return (
    <Flex
      h="100vh"
      w="100vw"
      direction="column"
      align="center"
      justify="center"
      gap="md"
    >
      <DwellirShieldTextIcon />
      <Flex direction="row" justify="center" align="center">
        <Paper p="md" shadow="lg" radius="md" mx="sm" withBorder>
          <Loading isLoading={user.state === "loading"}>
            <Flex direction="column" justify="space-between" gap="md">
              <>
                <Text size="xl" fw={700} c="green">
                  Please confirm your email address
                </Text>
                <Text>
                  Click the link in the email we just sent you to use the API
                  Platform.
                </Text>
                <Text size="sm" c="dimmed" fw={400}>
                  You can close this tab. The link in the email will open a new
                  tab in the Platform.
                </Text>
              </>
            </Flex>
          </Loading>
        </Paper>
      </Flex>
    </Flex>
  );
};
