import { FC, useEffect, useRef, useState } from "react";
import { CheckIcon, Flex, Paper, StyleProp, Tabs, Text } from "@mantine/core";
import { Chain } from "src/api/chain";
import { ChartTooltip, LineChart } from "@mantine/charts";
import { AnalyticsV4Input, AnalyticsV4Row, Interval } from "src/api/user";
import {
  hashStringToColor,
  initChart,
  populateV4Analytics,
} from "src/utility/chart";
import { ALL_CHAINS } from "src/ui/components/select-chain";
import { ApiKey } from "src/api/api_keys";
import { ALL_KEYS } from "src/ui/components/select-api-key";
import { RequestState } from "src/store/core/request_state";
import { Loading } from "src/ui/components/loading";
import { useRefDimensions } from "src/utility/use-ref-dimensions";
import { useTranslation } from "react-i18next";

const dayFormat = new Intl.DateTimeFormat("en", {
  timeZone: "UTC",
  day: "numeric",
  month: "short",
});

export function formatDateToShort(interval: Interval, dateString: string) {
  const date = new Date(dateString);
  switch (interval) {
    case "day": {
      return dayFormat.format(date);
    }
    case "hour": {
      return date.toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
      });
    }
    case "minute": {
      return date.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      });
    }
  }
}

interface UsageGraphProps {
  analyticsInput: AnalyticsV4Input;
  analytics: RequestState<AnalyticsV4Row[]>;
  apiKey: ApiKey;
  chain: Chain;
  chains: RequestState<Chain[]>;
  h?: StyleProp<React.CSSProperties["height"]>;
}

interface MetricData {
  name: string;
  [key: string]: number | string | string[];
}

interface ResponsesGraphProps {
  data: MetricData[];
  h?: StyleProp<React.CSSProperties["height"]>;
}

const ResponsesGraph: FC<ResponsesGraphProps> = ({ data, h }) => {
  const { t } = useTranslation();
  const [series, setSeries] = useState<{ name: string; color: string }[]>([]);
  useEffect(() => {
    const hosts = new Set(
      data
        .map((row) => Object.keys(row).filter((key) => key !== "name"))
        .flat(),
    );

    const res = Array.from(hosts).map((host) => ({
      name: host,
      color: hashStringToColor(host),
    }));
    setSeries(res);
  }, [data]);

  return (
    <LineChart
      h={h}
      data={data}
      dataKey="name"
      series={series}
      withLegend
      legendProps={{
        verticalAlign: "bottom",
        height: 100,
        wrapperStyle: {
          overflow: "auto",
        },
      }}
      tooltipProps={{
        content: ({ label, payload }) => (
          // We use the regular tooltip here but sort the data so that it shows
          // in descending order.
          <ChartTooltip
            label={label}
            valueFormatter={(value) => t("format.number", { number: value })}
            payload={payload && payload.sort((a, b) => b.value - a.value)}
          />
        ),
        wrapperStyle: { zIndex: 9 },
      }}
      curveType="linear"
      tickLine="xy"
      gridAxis="xy"
    />
  );
};

export const UsageGraph: FC<UsageGraphProps> = ({
  analyticsInput,
  analytics,
  apiKey,
  chain,
  chains,
  h,
}) => {
  // This bogus data gives us a decent loading state on first page load.
  const [data, setData] = useState<MetricData[]>([
    {
      name: "Mar 22",
      Apples: 2890,
      Oranges: 2338,
      Tomatoes: 2452,
    },
    {
      name: "Mar 23",
      Apples: 2756,
      Oranges: 2103,
      Tomatoes: 2402,
    },
    {
      name: "Mar 24",
      Apples: 3322,
      Oranges: 986,
      Tomatoes: 1821,
    },
    {
      name: "Mar 25",
      Apples: 3470,
      Oranges: 2108,
      Tomatoes: 2809,
    },
    {
      name: "Mar 26",
      Apples: 3129,
      Oranges: 1726,
      Tomatoes: 2290,
    },
  ]);
  const flexRef = useRef<HTMLDivElement | null>(null);
  const dimensions = useRefDimensions(flexRef);
  const [graphHeight, setGraphHeight] = useState<number>(0);
  const [responseNumber, setResponseNumber] = useState<number>(50);

  const { t } = useTranslation();

  useEffect(() => {
    if (dimensions.height) {
      // We need to remove some height here as we can not get the exact panel
      // size (we account for the text + padding)
      setGraphHeight(dimensions.height * 0.8);
    }
  }, [dimensions.height]);

  useEffect(() => {
    if (analytics.state === "fulfilled" && chains.state === "fulfilled") {
      const internalChart = initChart(analyticsInput);
      const lookupHostName = new Map();
      chains.data.map((chain) => {
        chain.networks.map((network) => {
          const host = network.hostSlug;
          const hostName = `${chain.name} - ${network.name}`;
          lookupHostName.set(host, hostName);
        });
      });

      const totalResponses = analytics.data.reduce(
        (total, analytic) => total + analytic.responses,
        0,
      );

      setResponseNumber(totalResponses);

      let filterApiKeys = undefined;
      if (apiKey.id != ALL_KEYS.id) {
        filterApiKeys = [apiKey.apiKey];
      }

      let filterDomains = undefined;
      if (chain.id !== ALL_CHAINS.id) {
        filterDomains = chain.networks.map((n) => n.hostSlug);
      }

      populateV4Analytics(
        internalChart,
        analytics.data,
        lookupHostName,
        filterApiKeys,
        filterDomains,
      );

      const metric = Object.entries(internalChart.data).map(([date, usage]) => {
        let res = {
          name: formatDateToShort(internalChart.interval, date),
        };
        for (const hostUsage of Object.values(usage.byHost)) {
          res = { [hostUsage.hostName]: hostUsage.responses, ...res };
        }
        return res;
      });
      setData(metric);
    }
  }, [analyticsInput, analytics, apiKey, chain, chains]);

  return (
    <Paper p="md" h={h} radius="md" bg="second-background">
      <Flex ref={flexRef} direction="column" m="lg" gap="md" h="100%">
        <Tabs
          variant="outline"
          defaultValue="errors"
          style={{ list: { borderWidth: "none" } }}
          h="100%"
        >
          <Flex direction="row" justify="space-between">
            <Flex direction="row" gap={"sm"}>
              <Text fw={700} size="lg">
                Responses:
              </Text>
              <Loading isLoading={analytics.state === "loading"}>
                <Text c="var(--mantine-color-green-9)" size="lg" fw={400}>
                  {t("format.number", {
                    number: responseNumber,
                  })}
                </Text>
              </Loading>
            </Flex>

            <Tabs.List justify="flex-end" mb="md">
              <Tabs.Tab
                value="successful"
                leftSection={<CheckIcon height={12} width={12} />}
              >
                Successful
              </Tabs.Tab>
              {/*
              <Tabs.Tab
                value="errors"
                leftSection={<AlertTriangleIcon height={12} width={12} />}
              >
                Errors
              </Tabs.Tab>
              <Tabs.Tab
                value="rate_limited"
                leftSection={<HandStopIcon height={12} width={12} />}
              >
                Rate Limited
              </Tabs.Tab>
                */}
            </Tabs.List>
          </Flex>

          <Tabs.Panel h="100%" value="successful">
            <Loading
              isLoading={
                analytics.state === "loading" || chains.state === "loading"
              }
            >
              <ResponsesGraph data={data} h={graphHeight} />
            </Loading>
          </Tabs.Panel>

          <Tabs.Panel value="errors">
            <Loading
              isLoading={
                analytics.state === "loading" || chains.state === "loading"
              }
            >
              <ResponsesGraph data={data} h={graphHeight} />
            </Loading>
          </Tabs.Panel>

          <Tabs.Panel value="rate_limited">
            <Loading
              isLoading={
                analytics.state === "loading" || chains.state === "loading"
              }
            >
              <ResponsesGraph data={data} h={graphHeight} />
            </Loading>
          </Tabs.Panel>
        </Tabs>
      </Flex>
    </Paper>
  );
};
