import { useEffect, useRef, useState } from "react";
import { RequestChain } from "../requst-chain";
import { Loading } from "../loading";
import * as classes from "./endpoints-search.module.css";
import { EndpointsSearchResult } from "./endpoints-search-result";
import { EndpointsSearchInput } from "./endpoints-search-input";
import { Paper, Flex, Box, ScrollArea } from "@mantine/core";
import { useChains } from "src/store/chain";
import { useUserApiKeys } from "src/store/api_keys";
import { ApiKey } from "src/api/api_keys";

export const EndpointsSearch = () => {
  const [maxScrollHeight, setMaxScrollHeight] = useState(0);
  const scrollRef = useRef<HTMLDivElement | null>(null);
  const [filter, setFilter] = useState("");
  const chains = useChains();
  const [isEmpty, setIsEmpty] = useState(false);
  const apiKeys = useUserApiKeys();
  const [apiKey, setApiKey] = useState<ApiKey | null>(null);

  useEffect(() => {
    if (apiKeys && apiKeys.length >= 1 && apiKey == null) {
      setApiKey(apiKeys[0]);
    }
  }, [apiKeys, apiKey]);

  const showResults = filter !== "";

  useEffect(() => {
    function handleResize() {
      if (scrollRef.current?.clientHeight) {
        setMaxScrollHeight(scrollRef.current?.clientHeight);
      }
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Flex
      direction="column"
      mx={{ base: "xs", md: "xl" }}
      mt="xl"
      mb="5vh"
      px={0}
      h="100%"
      ref={scrollRef}
    >
      <Paper mx={{ base: 0, md: "xl" }} shadow="xl">
        <Loading isLoading={chains.state === "loading" || apiKeys == null}>
          <EndpointsSearchInput
            showResults={showResults}
            onChange={(value) => {
              setFilter(value);
              // When the filter changes, set isEmpty to false to re-calculate the results
              setIsEmpty(false);
            }}
          />
          {showResults && (
            <Paper
              classNames={{
                root: classes.dropdown,
              }}
              bg="background"
              withBorder
            >
              {isEmpty ? (
                <Box p="xs">
                  <RequestChain initialRequest={filter} />
                </Box>
              ) : (
                <ScrollArea.Autosize mah={maxScrollHeight}>
                  <Flex direction="column" m="xs" gap="md">
                    {chains.state === "fulfilled" && apiKey != null && (
                      <EndpointsSearchResult
                        filter={filter}
                        chains={chains.data}
                        isEmpty={setIsEmpty}
                        apiKey={apiKey}
                      />
                    )}
                  </Flex>
                </ScrollArea.Autosize>
              )}
            </Paper>
          )}
        </Loading>
      </Paper>
    </Flex>
  );
};
