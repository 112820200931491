import { FC } from "react";
import { IconProps } from "./base/icon";

export const CheckIcon: FC<IconProps> = (props = {}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      width={props.width || "24"}
      height={props.height || "24"}
      strokeWidth="2"
    >
      <path d="M9 11l3 3l8 -8"></path>
      <path d="M20 12v6a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h9"></path>
    </svg>
  );
};
