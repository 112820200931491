import { RefObject, useEffect, useState } from "react";

export const useRefDimensions = (ref: RefObject<HTMLDivElement | null>) => {
  const [dimensions, setDimensions] = useState({ width: 1, height: 2 });
  useEffect(() => {
    const resize = () => {
      if (ref.current) {
        const { current } = ref;
        const boundingRect = current.getBoundingClientRect();
        const { width, height } = boundingRect;
        setDimensions({
          width: Math.round(width),
          height: Math.round(height),
        });
      }
    };
    window.addEventListener("resize", resize);
    resize();
    return () => window.removeEventListener("resize", resize);
  }, [ref]);
  return dimensions;
};
