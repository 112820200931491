import { useMemo } from "react";

const useNumberFormatter = () => {
  const formatNumber = useMemo(() => {
    return (num: number): string => {
      return new Intl.NumberFormat("en", {
        notation: "compact",
        compactDisplay: "short",
      }).format(num);
    };
  }, []);

  return formatNumber;
};

export default useNumberFormatter;
