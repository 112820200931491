import { FC } from "react";
import { BaseIconProps } from "./base/icon";

export const FilterIcon: FC<BaseIconProps> = (_props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      width="1rem"
      height="1rem"
      strokeWidth="2"
    >
      <path d="M4 4h16v2.172a2 2 0 0 1 -.586 1.414l-4.414 4.414v7l-6 2v-8.5l-4.48 -4.928a2 2 0 0 1 -.52 -1.345v-2.227z"></path>
    </svg>
  );
};
