import { FC } from "react";
import { Stack, Text } from "@mantine/core";

interface ThresholdTextRowProps {
  label: string;
  number: number;
  warningThreshold: number | undefined;
  errorThreshold: number | undefined;
}

const ThresholdTextRow: FC<ThresholdTextRowProps> = ({
  label,
  number,
  warningThreshold,
  errorThreshold,
}) => {
  let numberColor = "green.6";

  if (errorThreshold != null && number >= errorThreshold) {
    numberColor = "red.6";
  } else if (warningThreshold != null && number >= warningThreshold) {
    numberColor = "yellow.6";
  }

  return (
    <Stack gap="xs" align="center">
      <Text fw={600} size="sm">
        {label}
      </Text>
      <Text fw={700} size="xl" c={numberColor}>
        {number}
      </Text>
    </Stack>
  );
};

export default ThresholdTextRow;
