import { FC, useEffect, useState } from "react";
import { RootPanel } from "../components/panels/root-panel/root-panel";
import { DwellirLogoBackground } from "../components/dwellir-logo-background";
import { theme } from "../theme";
import { Loading } from "../components/loading";
import { EndpointsSearch } from "../components/endpoints-search";

import { MonthlyProgressBar } from "../components/monthly-progress-bar";
import { Text, NavLink, Paper, Flex, Box, Center, Grid } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useCurrentSubscription, useRpsAnalytics } from "src/store/user";
import { useAnalyticsV4MonthlySummary } from "src/store/organization";
import { thisMonth } from "src/utility/intervals";

interface ViewLinkProps {
  href: string;
  label: string;
}

export const ViewLink: FC<ViewLinkProps> = ({ href, label }) => {
  const navigate = useNavigate();
  // Use a button component and onClick here to prevent a full page reload
  // which causes us to loose our redux state.
  return (
    <NavLink
      label={label}
      style={{
        borderRadius: "var(--mantine-radius-lg)",
      }}
      component="button"
      onClick={(e) => {
        e.stopPropagation();
        navigate(href);
      }}
      active
      fw={700}
      variant="subtle"
      color="green"
    />
  );
};

interface HedingProps {
  label: string;
}

export const Heading: FC<HedingProps> = ({ label }) => {
  return (
    <Flex direction="row" gap="md" align="center">
      <hr
        style={{
          width: "100%",
          height: "0px",
          border: "1px solid #94CFBE",
        }}
      />
      <Text style={{ whiteSpace: "nowrap" }}>{label}</Text>
      <hr
        style={{
          width: "100%",
          height: "0px",
          border: "1px solid #94CFBE",
        }}
      />
    </Flex>
  );
};

const AccountStatsCard = () => {
  const { t } = useTranslation();
  // to be used once the useanalyticsv4 data is based on the organization
  // clickhouse tables.
  // const analytics = useanalyticsv4({
  //   interval: "day",
  //   startTime: startOfCurrentMonth().toISOString(),
  // });
  const montlySummary = useAnalyticsV4MonthlySummary();
  const rpsAnalytics = useRpsAnalytics(thisMonth());
  const subscription = useCurrentSubscription();

  // Raw numbers
  const [responsesThisCycle, setResponsesThisCycle] = useState<number>(0);

  // Progress bars (percentages, 0-100)
  const [progressThisCycle, setProgressThisCycle] = useState<
    number | undefined
  >(undefined);
  const [prognosisThisCycle, setPrognosisThisCycle] = useState<
    number | undefined
  >(undefined);

  useEffect(() => {
    if (
      montlySummary.state === "fulfilled" &&
      subscription.state === "fulfilled"
    ) {
      // To be used once the useAnalyticsV4 data is based on the organization
      // clickhouse tables.
      //const responsesThisMonth = analytics.data.reduce(
      //  (acc, row) => row.requests + acc,
      //  0,
      //);
      const responsesThisMonth = montlySummary.data.totalResponses;
      setResponsesThisCycle(responsesThisMonth);

      // Simple linear prognosis, if we want to do something fancier we should
      // move the logic to the backend.
      const daysInMonth = (year: number, month: number) =>
        new Date(year, month, 0).getDate();
      const today = new Date();
      const daysThisMonth = daysInMonth(
        today.getFullYear(),
        today.getMonth() + 1,
      );
      const daysSoFar = today.getDate();
      const daysLeftThisMonth = daysThisMonth - daysSoFar;

      const averageResponsesPerDay = responsesThisMonth / daysSoFar;
      const estimated = averageResponsesPerDay * daysLeftThisMonth;
      const estimatedTotal = responsesThisMonth + estimated;

      // Progressbar parts
      if (
        subscription.data.monthlyQuota == null ||
        subscription.data.monthlyQuota === 0
      ) {
        setProgressThisCycle(undefined);
      } else {
        const progress =
          (responsesThisMonth / subscription.data.monthlyQuota) * 100;
        setProgressThisCycle(progress);
      }

      if (
        subscription.data.monthlyQuota == null ||
        subscription.data.monthlyQuota === 0
      ) {
        setPrognosisThisCycle(undefined);
      } else {
        setPrognosisThisCycle(
          (estimatedTotal / subscription.data.monthlyQuota) * 100,
        );
      }
    }
  }, [montlySummary, subscription]);

  return (
    <Paper
      p={{ base: "md", lg: "xl", xl: "xl" }}
      radius="md"
      shadow="md"
      bg="second-background"
      mb="md"
      w="100%"
      m={0}
    >
      <Flex direction="row" justify="space-between" visibleFrom="lg">
        <Flex direction="column">
          <Box pb="8px">
            <Heading label="This Month" />
          </Box>
          <Loading
            isLoading={
              montlySummary.state === "loading" ||
              subscription.state == "loading"
            }
          >
            <Box pb="4px">
              <Center>
                <Text
                  c="green"
                  ta="right"
                  size="calc(2.5rem * var(--mantine-scale))"
                  fw={700}
                >
                  {responsesThisCycle != null
                    ? t("format.number", {
                        number: responsesThisCycle,
                      })
                    : "-"}
                </Text>
              </Center>
            </Box>
            <Flex direction="row" w="100%" justify="flex-end">
              <Text size="md" fw={600}>
                /{" "}
                {subscription.state === "fulfilled"
                  ? t("format.number", {
                      number: subscription.data.monthlyQuota,
                    })
                  : "-"}{" "}
                RESPONSES
              </Text>
            </Flex>
            <MonthlyProgressBar
              progress={progressThisCycle}
              prognosis={prognosisThisCycle}
            />
          </Loading>
        </Flex>

        <Flex direction="column" visibleFrom="sm">
          <Box pb="8px">
            <Heading label="This Month" />
          </Box>
          <Loading isLoading={rpsAnalytics.state === "loading"}>
            <Grid columns={8} gutter="0px">
              <Grid.Col span={3}>
                <Flex direction="column">
                  <Box pb="4px">
                    <Center>
                      <Text size="calc(2.5rem * var(--mantine-scale))" fw={700}>
                        {rpsAnalytics.state === "fulfilled"
                          ? t("format.compact_number", {
                              number:
                                rpsAnalytics.data.rps > 1
                                  ? Math.round(rpsAnalytics.data.rps)
                                  : rpsAnalytics.data.rps,
                            })
                          : "-"}
                      </Text>
                    </Center>
                  </Box>
                  <Center>
                    <Text size="lg" fw={600}>
                      AVERAGE RPS
                    </Text>
                  </Center>
                </Flex>
              </Grid.Col>

              <Grid.Col span={2}>
                <Flex direction="column">
                  <Box pb="4px">
                    <Center>
                      <Text
                        c={theme.colors.yellow[3]}
                        size="calc(2.5rem * var(--mantine-scale))"
                        fw={700}
                      >
                        {rpsAnalytics.state === "fulfilled"
                          ? t("format.compact_number", {
                              number: rpsAnalytics.data.peakRps,
                            })
                          : "-"}
                      </Text>
                    </Center>
                  </Box>
                  <Center>
                    <Text size="lg" fw={600}>
                      PEAK RPS
                    </Text>
                  </Center>
                </Flex>
              </Grid.Col>

              <Grid.Col span={3} w="22em">
                <Flex direction="column">
                  <Box pb="4px">
                    <Center>
                      <Text
                        c={theme.colors.red[7]}
                        size="calc(2.5rem * var(--mantine-scale))"
                        fw={700}
                      >
                        {rpsAnalytics.state === "fulfilled"
                          ? t("format.compact_number", {
                              number: rpsAnalytics.data.limitedRequests,
                            })
                          : "-"}
                      </Text>
                    </Center>
                  </Box>
                  <Center>
                    <Text size="lg" fw={600}>
                      LIMITED REQUESTS
                    </Text>
                  </Center>
                </Flex>
              </Grid.Col>
            </Grid>
          </Loading>
        </Flex>

        <Flex direction="column" visibleFrom="sm">
          <Box pb="8px">
            <Heading label="Your Plan's RPS Limits" />
          </Box>
          <Loading isLoading={subscription.state === "loading"}>
            <Flex direction="row" gap="md">
              <Flex direction="column">
                <Box pb="4px">
                  <Center>
                    <Text size="calc(2.5rem * var(--mantine-scale))" fw={700}>
                      {subscription.state === "fulfilled"
                        ? t("format.number", {
                            number: subscription.data.rateLimit,
                          })
                        : "-"}
                    </Text>
                  </Center>
                </Box>
                <Text size="lg" fw={600}>
                  RATE LIMIT
                </Text>
              </Flex>

              <Flex direction="column">
                <Box pb="4px">
                  <Center>
                    <Text size="calc(2.5rem * var(--mantine-scale))" fw={700}>
                      {subscription.state === "fulfilled"
                        ? t("format.number", {
                            number: subscription.data.burstLimit,
                          })
                        : "-"}
                    </Text>
                  </Center>
                </Box>
                <Text size="lg" fw={600}>
                  BURST LIMIT
                </Text>
              </Flex>
            </Flex>
          </Loading>
        </Flex>
      </Flex>
      <Grid hiddenFrom="lg">
        <Grid.Col span={12}>
          <Loading
            isLoading={
              montlySummary.state === "loading" ||
              subscription.state == "loading"
            }
          >
            <Box pb="4px">
              <Center>
                <Text c="green" ta="right" size="xl" fw={700}>
                  {responsesThisCycle != null
                    ? t("format.number", {
                        number: responsesThisCycle,
                      })
                    : "-"}
                </Text>
                <Text size="10px" ml={2} fw={600}>
                  /{" "}
                  {subscription.state === "fulfilled"
                    ? t("format.number", {
                        number: subscription.data.monthlyQuota,
                      })
                    : "-"}{" "}
                  RESPONSES
                </Text>
              </Center>
            </Box>
            <MonthlyProgressBar
              progress={progressThisCycle}
              prognosis={prognosisThisCycle}
            />
          </Loading>
        </Grid.Col>
        <Grid.Col span={12}>
          <Loading isLoading={rpsAnalytics.state === "loading"}>
            <Grid>
              <Grid.Col span={4} ta={"center"}>
                <Box pb="4px">
                  <Center>
                    <Text size="lg" fw={700}>
                      {rpsAnalytics.state === "fulfilled"
                        ? t("format.compact_number", {
                            number: rpsAnalytics.data.rps,
                          })
                        : "-"}
                    </Text>
                  </Center>
                </Box>
                <Center>
                  <Text size="10px" fw={600}>
                    AVERAGE RPS
                  </Text>
                </Center>
              </Grid.Col>
              <Grid.Col span={4} ta={"center"}>
                <Flex direction="column">
                  <Box pb="4px">
                    <Center>
                      <Text c={theme.colors.yellow[3]} size="lg" fw={700}>
                        {rpsAnalytics.state === "fulfilled"
                          ? t("format.compact_number", {
                              number: rpsAnalytics.data.peakRps,
                            })
                          : "-"}
                      </Text>
                    </Center>
                  </Box>
                  <Center>
                    <Text size="10px" fw={600}>
                      PEAK RPS
                    </Text>
                  </Center>
                </Flex>
              </Grid.Col>
              <Grid.Col span={4} ta={"center"}>
                <Box pb="4px">
                  <Center>
                    <Text c={theme.colors.red[7]} size="lg" fw={700}>
                      {rpsAnalytics.state === "fulfilled"
                        ? t("format.compact_number", {
                            number: rpsAnalytics.data.limitedRequests,
                          })
                        : "-"}
                    </Text>
                  </Center>
                </Box>
                <Center>
                  <Text size="10px" fw={600}>
                    LIMITED REQUESTS
                  </Text>
                </Center>
              </Grid.Col>
            </Grid>
          </Loading>
        </Grid.Col>
      </Grid>
    </Paper>
  );
};

export const Dashboard = () => {
  return (
    <RootPanel withNavbar>
      <Box h="100%" p="xs">
        <DwellirLogoBackground>
          <Flex
            h="100%"
            direction="column"
            p={{ base: "xs", lg: "xl" }}
            pb={{ base: 60, md: "md" }}
          >
            <EndpointsSearch />
            <Flex flex="1" align="flex-end">
              <AccountStatsCard />
            </Flex>
          </Flex>
        </DwellirLogoBackground>
      </Box>
    </RootPanel>
  );
};
