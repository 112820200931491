import { FC } from "react";

export interface BaseIconProps {
  h?: number;
  w?: number;
  fillColor?: string;
}

export type IconProps = {
  fillColor?: string;
  viewBox?: string;
  width?: string | number;
  height?: string | number;
  children?: React.ReactNode; // SVG Path
};

export const Icon: FC<IconProps> = ({
  fillColor = "currentColor",
  viewBox = "0 0 512 512",
  width = "1em",
  height = "1em",
  children,
}: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox={viewBox}
      width={width}
      height={height}
      style={{
        fill: fillColor,
      }}
    >
      {children}
    </svg>
  );
};
