import { FC, useEffect, useState } from "react";
import { UrlClickToCopy } from "../url-click-to-copy";
import { NodeTypeBadge } from "../node-type-badge/node-type-badge";
import * as classes from "./endpoints-search.module.css";
import { Flex, Paper, Text, Image, Badge } from "@mantine/core";
import { theme } from "src/ui/theme";
import { Chain } from "src/api/chain";
import { endpointUrl } from "src/utility/endpoint";
import { ApiKey } from "src/api/api_keys";
import { ViewLink } from "src/ui/pages/dashboard";
import { useNavigate } from "react-router-dom";
import { PageRoute } from "src/utility/utils";

interface EndpointSearchData {
  id: number;
  label: string;
  imageUrl: string;
  networkName: string;
  nodeType: string;
  https?: string;
  wss?: string;
}

interface EndpointsSearchResultProps {
  filter: string;
  chains: Chain[];
  apiKey: ApiKey;
  isEmpty?: (empty: boolean) => void;
}

export const EndpointsSearchResult: FC<EndpointsSearchResultProps> = ({
  filter,
  chains,
  apiKey,
  isEmpty,
}) => {
  const [data, setData] = useState<EndpointSearchData[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    const filteredChains = chains.filter(
      (chain) =>
        chain.name.toLowerCase().includes(filter.toLowerCase()) ||
        chain.networks.some((network) =>
          network.name.toLowerCase().includes(filter.toLowerCase()),
        ),
    );

    if (filteredChains.length === 0) {
      isEmpty && isEmpty(true);
    } else {
      isEmpty && isEmpty(false);
    }

    // Sort based on if the Chain starts with filter or not
    filteredChains.sort((a: Chain, b: Chain) => {
      const aStarts = a.name.toLowerCase().startsWith(filter.toLowerCase());
      const bStarts = b.name.toLowerCase().startsWith(filter.toLowerCase());
      if (aStarts && !bStarts) {
        return -1;
      }
      if (!aStarts && bStarts) {
        return 1;
      }
      return 0;
    });

    const key = apiKey.apiKey;
    if (filteredChains) {
      const data = filteredChains.flatMap((chain) => {
        return chain.networks.flatMap((network) => {
          return {
            id: chain.id,
            label: chain.name,
            imageUrl: chain.imageUrl,
            networkName: network.name,
            nodeType: network.nodeType,
            https: network.https ? endpointUrl(network.https, key) : undefined,
            wss: network.wss ? endpointUrl(network.wss, key) : undefined,
          };
        });
      });
      setData(data);
    }
  }, [filter, chains, isEmpty, apiKey]);

  const renderResult = (index: number, label: string) => {
    const chain = data[index];
    return (
      <Paper
        key={`EndpointsSearchResult-${index}`}
        component="a"
        onClick={() => navigate(`${PageRoute.USAGE}?chainId=${chain.id}`)}
        classNames={{
          root: classes.row,
        }}
        w="100%"
        py="xs"
        px="sm"
        shadow="none"
        radius="md"
      >
        <Flex
          direction="row"
          w="inherit"
          justify="space-between"
          align="center"
        >
          <Flex direction="row" gap="xs" align="inherit">
            <Image h={48} w={48} src={chain.imageUrl} />
            <Text size="xl" fw={400}>
              <ViewLink
                label={label}
                href={`${PageRoute.USAGE}?chainId=${chain.id}`}
              />
            </Text>
            <Badge
              variant="outline"
              color="primaryText"
              classNames={{
                root:
                  chain.networkName.toLowerCase() === "mainnet"
                    ? classes.badge_mainnet
                    : classes.badge_testnet,
              }}
            >
              {chain.networkName}
            </Badge>
            <NodeTypeBadge label={chain.nodeType} />
          </Flex>
          <Flex direction="row" gap="xs">
            <UrlClickToCopy
              variant="filled"
              color={theme.colors.green[6]}
              url={chain.https || ""}
              text="HTTPS"
              hidden={chain.https == null}
              width={"4em"}
            />
            <UrlClickToCopy
              variant="filled"
              color={theme.colors.green[6]}
              url={chain.wss || ""}
              text="Websocket"
              hidden={chain.wss == null}
              width={"5em"}
            />
          </Flex>
        </Flex>
      </Paper>
    );
  };

  return data.map((chain, index) => renderResult(index, chain.label));
};
