import { FC, useState } from "react";
import { Group, Select, StyleProp } from "@mantine/core";
import { NumberIcon } from "src/ui/icons/number-icon";

export interface LogClass {
  id: number;
  name: string;
}

interface SelectLogClassProps {
  logClass?: LogClass;
  logClasses: LogClass[];
  onSelect: (logClass: LogClass) => void;
  w?: StyleProp<React.CSSProperties["width"]>;
}

export const ALL_LOGS: LogClass = {
  id: -1,
  name: "All Logs",
};

export const SelectLogClass: FC<SelectLogClassProps> = ({
  logClass,
  logClasses,
  onSelect,
  w = undefined,
}) => {
  const [selectedLogClass, setSelectedLogClass] = useState<LogClass>(
    logClass ?? ALL_LOGS,
  );

  const allClasses = [ALL_LOGS, ...logClasses];

  const handleSelect = (value: string | null) => {
    const logClass = allClasses.find(
      (logClass) => logClass.id.toString() === value,
    );
    if (logClass) {
      setSelectedLogClass(logClass);
      onSelect(logClass);
    }
  };

  const renderSelectOption = ({
    option,
  }: {
    option: { value: string; label: string };
  }) => {
    return (
      <Group flex="1" gap="xs">
        {option.label === ALL_LOGS.name
          ? option.label
          : `${option.value} - ${option.label}`}
      </Group>
    );
  };

  return (
    <Select
      withCheckIcon={false}
      searchable
      w={w}
      renderOption={renderSelectOption}
      leftSection={<NumberIcon h={16} w={16} />}
      disabled={logClasses.length == 0}
      onChange={handleSelect}
      data={allClasses.map((logClass) => ({
        value: logClass.id.toString(),
        label: logClass.name,
      }))}
      value={selectedLogClass.id.toString()}
    />
  );
};
