export const DwellirLogo = () => {
  return (
    <svg
      width="190"
      height="40"
      viewBox="0 0 1114 216"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M156.933 120.531C156.68 106.398 156.88 92.2567 156.88 78.1196H156.808C156.808 62.2414 156.812 46.3611 156.804 30.4828C156.8 24.8547 156.499 24.3028 151.13 23.0298C132.624 18.6448 114.114 14.2698 95.5579 10.1C89.6309 8.76721 83.6063 7.85874 77.4323 8.87878C55.5255 12.4887 34.0748 18.095 12.5824 23.5278C10.0921 24.1554 8.76123 25.5679 8.61978 28.114C8.57396 28.9169 8.49227 29.7178 8.49227 30.5207C8.47633 60.9384 8.50622 91.3562 8.44446 121.776C8.42255 133.024 11.1938 143.521 16.5848 153.333C24.8168 168.317 36.6986 179.994 50.3058 190.045C59.3845 196.753 69.025 202.512 79.4963 206.841C81.8332 207.808 83.8972 207.796 86.2281 206.825C94.494 203.389 102.234 199.008 109.631 194.009C123.404 184.701 135.648 173.786 144.946 159.856C152.885 147.966 157.192 135.005 156.933 120.531Z"
        fill="#29594B"
      />
      <path
        d="M16.9363 31.1385C16.9324 40.7671 16.9304 50.3957 16.9304 60.0242C16.9304 80.2676 16.9304 101.202 16.8905 121.794C16.8706 131.556 19.1916 140.543 23.9849 149.269C30.8204 161.713 40.7777 172.511 55.3231 183.255C64.5453 190.071 73.5084 195.235 82.7226 199.04C82.7685 199.06 82.8083 199.074 82.8422 199.086C82.882 199.072 82.9278 199.056 82.9836 199.032C90.2195 196.024 97.3896 192.095 104.902 187.017C119.326 177.269 130.124 166.855 137.924 155.172C145.252 144.195 148.708 132.911 148.489 120.684C148.312 110.769 148.356 100.732 148.397 91.0257C148.403 89.5535 148.409 88.0652 148.415 86.5671H148.362L148.364 64.7499C148.366 53.5175 148.366 42.2872 148.36 31.0548C128.943 26.4547 111.385 22.3148 93.7079 18.3422C88.9285 17.2664 83.8124 16.3858 78.8059 17.2126C59.0168 20.472 39.1082 25.5203 19.855 30.3994L16.9363 31.1385ZM82.8402 216C80.6447 216 78.4492 215.548 76.2697 214.649C65.7705 210.308 55.6339 204.483 45.2861 196.838C28.9337 184.759 17.1256 171.86 9.18247 157.402C3.06425 146.265 -0.0257379 134.274 0.000161463 121.762C0.0419988 101.184 0.0439917 80.2596 0.0439917 60.0222C0.0439917 50.1885 0.0439914 40.3527 0.047976 30.5189C0.047976 29.6742 0.0997742 28.9311 0.145596 28.2756C0.161534 28.0665 0.175482 27.8573 0.187435 27.6481C0.534088 21.488 4.39309 16.8899 10.5113 15.3439L15.7071 14.029C35.301 9.06032 55.5642 3.92628 76.0585 0.549403C83.9399 -0.749548 91.2754 0.485643 97.4115 1.86428C115.428 5.91255 133.278 10.1262 153.075 14.8179C156.022 15.5152 160.056 16.4715 162.746 20.0755C165.242 23.4205 165.244 27.1719 165.246 30.4811C165.254 41.9047 165.252 53.3283 165.252 64.7519L165.25 69.6787H165.322V78.1219C165.322 82.4531 165.304 86.848 165.286 91.0975C165.244 100.712 165.202 110.655 165.377 120.382C165.652 135.947 161.144 150.807 151.969 164.548C142.933 178.085 130.63 190.013 114.358 201.008C105.899 206.726 97.7562 211.181 89.4684 214.627C87.269 215.542 85.0536 216 82.8402 216Z"
        fill="#52B196"
      />
      <path
        d="M71.7122 113.483C68.2836 112.728 65.7375 113.662 63.6536 115.686C59.3941 119.82 55.1506 123.978 51.0725 128.287C47.7235 131.827 47.7554 137.105 50.8553 140.35C54.1226 143.769 59.5415 143.888 63.2671 140.364C67.6978 136.17 71.9852 131.823 76.2486 127.458C78.3943 125.263 79.492 122.655 78.4022 118.913C76.2765 120.836 74.5552 122.531 72.6845 124.046C71.0548 125.366 69.2618 125.247 67.7496 123.802C66.2076 122.33 66.2296 120.561 67.4289 118.898C68.6601 117.184 70.0407 115.58 71.7122 113.483ZM94.9718 102.838C98.0678 103.838 100.582 102.954 102.694 100.905C107.194 96.5384 111.727 92.1933 116.028 87.6331C119.65 83.796 119.421 78.64 115.835 75.2552C112.478 72.0815 107.507 72.1134 103.813 75.5939C99.3707 79.7816 95.0475 84.1028 90.7722 88.4658C88.6285 90.6553 87.5965 93.2592 88.7719 96.5404C90.9674 95.5722 92.095 93.9425 93.3641 92.4782C95.0615 90.5158 97.1334 90.5079 99.0002 91.8666C101.152 93.4345 100.881 95.5184 99.3727 97.5027C98.008 99.2957 95.8046 100.411 94.9718 102.838ZM70.3575 105.063C72.5948 104.956 74.7425 105.169 76.667 106.261C78.2528 107.163 79.3904 106.729 80.5738 105.514C81.7871 104.27 82.6876 103.222 81.9046 101.226C78.7947 93.2811 81.1615 86.7804 87.2678 81.1702C91.4037 77.3709 95.0974 73.0876 99.2751 69.3382C105.28 63.9531 114.082 63.9033 120.24 68.878C126.24 73.7251 127.922 82.2281 124.443 89.0834C122.937 92.0539 119.961 93.3947 117.903 95.7316C114.775 99.2878 111.653 102.864 108.278 106.177C103.501 110.865 97.7291 112.271 91.3798 109.998C89.2939 109.253 87.8754 108.956 86.0983 110.713C84.3989 112.395 84.9209 113.716 85.5604 115.549C87.8435 122.089 86.4749 127.934 81.6277 132.889C77.3503 137.26 73.073 141.633 68.6581 145.863C61.9462 152.296 52.3276 152.389 45.8547 146.233C39.4616 140.155 39.0273 130.164 45.1953 123.424C49.7636 118.429 54.6506 113.716 59.5575 109.048C62.5339 106.219 66.1997 104.76 70.3575 105.063Z"
        fill="#52B196"
      />
      <path
        d="M1089.74 93.5902C1089.74 98.6315 1088.47 101.961 1085.75 104.07C1082.65 106.462 1078.47 107.676 1073.3 107.676H1053.63V79.0627H1072.13C1077.87 79.0627 1082.4 80.3204 1085.59 82.8045C1088.42 85.0143 1089.74 88.4422 1089.74 93.5902ZM1094.61 123.382C1098.66 121.184 1102.11 118.278 1104.92 114.71C1109.33 109.108 1111.57 101.902 1111.57 93.2972C1111.57 82.0009 1107.9 73.1843 1100.67 67.0925C1093.73 61.2392 1084.17 58.2697 1072.28 58.2697H1032.1V157.729H1053.63V128.469H1071.59L1094.38 157.729H1113.48V147.688L1094.61 123.382Z"
        fill="#52B196"
      />
      <path d="M945.677 157.73H967.207V58.271H945.677V157.73Z" fill="#52B196" />
      <path
        d="M837.171 58.2697H815.644V157.729H883.049V136.938H837.171V58.2697Z"
        fill="#52B196"
      />
      <path
        d="M704.867 58.2697H683.337V157.729H750.743V136.938H704.867V58.2697Z"
        fill="#52B196"
      />
      <path
        d="M549.841 157.73H622.981V136.937H571.37V117.883H615.482V97.0878H571.37V79.0614H622.981V58.2704H549.841V157.73Z"
        fill="#52B196"
      />
      <path
        d="M466.497 115.876L451.306 58.2704H432.66L417.352 115.876L399.826 58.2704H377.281L410.434 157.73H426.878L441.979 101.449L457.08 157.73H473.524L506.677 58.2704H484.143L466.497 115.876Z"
        fill="#52B196"
      />
      <path
        d="M319.255 107.999C319.255 112.722 318.508 116.922 317.035 120.482C315.587 123.981 313.567 126.963 311.033 129.34C308.457 131.753 305.362 133.634 301.834 134.93C298.209 136.261 294.187 136.939 289.878 136.939H276.968V79.0611H289.878C294.183 79.0611 298.203 79.7391 301.834 81.0701C305.366 82.3718 308.461 84.2531 311.031 86.6576C313.561 89.0328 315.578 92.0316 317.027 95.5725C318.506 99.1866 319.255 103.368 319.255 107.999ZM326.885 71.7764C322.417 67.4194 316.983 64.0335 310.73 61.7106C304.588 59.4274 297.767 58.2702 290.466 58.2702H255.438V157.73H290.466C297.767 157.73 304.585 156.572 310.73 154.289C316.98 151.966 322.417 148.58 326.885 144.223C331.357 139.858 334.863 134.526 337.301 128.375C339.707 122.298 340.929 115.441 340.929 107.999C340.929 100.557 339.707 93.7016 337.301 87.6244C334.863 81.474 331.357 76.1418 326.885 71.7764Z"
        fill="#52B196"
      />
    </svg>
  );
};
