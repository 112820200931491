import api from "../api/marly";

import { createAsyncThunk } from "@reduxjs/toolkit";

export const logout = createAsyncThunk(
  "auth/logout",
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.logout();
      // We get no body, only a remove cookie header
      return response.data;
    } catch (error: unknown) {
      console.error("Unkwon error", error);
      return rejectWithValue({ type: "unknown", serverMessage: "" });
    }
  },
);

/* Selectors */
// Cookie helper functions - to have only one place where the cookie name is defined.
export const cookieSelector = ["marly_authenticated"];
export const isAuthenticated = (cookies: {
  [x: string]: boolean;
}): boolean | undefined => {
  if (Object.prototype.hasOwnProperty.call(cookies, "marly_authenticated")) {
    return cookies["marly_authenticated"];
  }
  return false;
};
