import { FC, useEffect, useState } from "react";
import { Flex, Paper, Text } from "@mantine/core";
import { ApiKey } from "src/api/api_keys";
import { useUserApiKeys } from "src/store/api_keys";
import { Loading } from "src/ui/components/loading";
import { SelectApiKey } from "src/ui/components/select-api-key";
import { SelectChain } from "src/ui/components/select-chain";
import { AnalyticsV4Input } from "src/api/user";
import { Chain } from "src/api/chain";
import {
  chainsHasDataPartition,
  HasDataPartition,
} from "src/ui/components/select-chain/chains-has-data-partition";
import { SelectInterval } from "src/ui/components/select-interval";
import { RequestState } from "src/store/core/request_state";
import { FilterIcon } from "src/ui/icons/filter-icon";
import {
  CURRENT_HOUR,
  PAST_30_DAYS,
  PAST_30_MINUTES,
  PAST_5_MINUTES,
  PAST_7_DAYS,
  PAST_8_HOURS,
  PAST_90_DAYS,
  PAST_MONTH,
  THIS_MONTH,
  TODAY,
} from "src/utility/intervals";
import { useUsageSummary } from "src/store/organization";
import { AnalyticsV4InputWihtoutFilter } from "src/api/organization";

interface UsageFilterProps {
  apiKey: ApiKey | null;
  onSelectApiKey: (key: ApiKey) => void;
  chain: Chain;
  chains: RequestState<Chain[]>;
  onSelectChain: (chain: Chain) => void;
  setAnalyticsInput: (input: AnalyticsV4Input) => void;
  input: AnalyticsV4InputWihtoutFilter;
}

export const UsageFilter: FC<UsageFilterProps> = ({
  apiKey,
  onSelectApiKey,
  chain,
  chains,
  onSelectChain,
  setAnalyticsInput,
  input,
}) => {
  const apiKeys = useUserApiKeys();
  const usageSummary = useUsageSummary(input);

  const [chainsDataPartition, setChainsDataPartition] = useState<
    HasDataPartition<Chain>
  >({ hasData: [], missingData: [] });

  useEffect(() => {
    if (chains.state === "fulfilled" && usageSummary.state === "fulfilled") {
      setChainsDataPartition(
        chainsHasDataPartition(apiKey, chains.data, usageSummary.data),
      );
    }
  }, [apiKey, usageSummary, chains]);

  return (
    <Paper radius="md">
      <Flex direction="column">
        <Flex direction="row" align="center" gap={undefined}>
          <FilterIcon />
          <Text fw={700} size="lg">
            Filters
          </Text>
        </Flex>
        <Loading
          isLoading={
            chains.state === "loading" ||
            apiKeys == null ||
            usageSummary.state === "loading"
          }
        >
          <Flex direction="row" gap="md" justify="space-between">
            {apiKeys != null && (
              <SelectApiKey
                selectedApiKey={apiKey}
                apiKeys={apiKeys}
                onSelect={(key: ApiKey) => onSelectApiKey(key)}
                hideLabel
                allowAllOption
                w={"100%"}
              />
            )}

            <SelectChain
              chain={chain}
              chains={chainsDataPartition}
              onSelect={onSelectChain}
              allowAllOption
              hideLabel
              w={"100%"}
            />
            <SelectInterval
              intervals={[
                PAST_5_MINUTES,
                PAST_30_MINUTES,
                CURRENT_HOUR,
                PAST_8_HOURS,
                TODAY,
                THIS_MONTH,
                PAST_30_DAYS,
                PAST_7_DAYS,
                PAST_MONTH,
                PAST_90_DAYS,
              ]}
              defaultInterval={THIS_MONTH}
              onSelect={setAnalyticsInput}
              hideLabel
              w={"100%"}
            />
          </Flex>
        </Loading>
      </Flex>
    </Paper>
  );
};
