import { FC, useEffect, useState } from "react";

import { countryName, currentCountry } from "src/utility/countries";
import { Group, Image, Select } from "@mantine/core";

interface CountrySelectorProps {
  value: string | undefined;
  onChange: (countryCode: string) => void;
  countries: { value: string; label: string }[];
}

export const CountrySelector: FC<CountrySelectorProps> = ({
  value,
  onChange,
  countries,
}) => {
  const guessed = currentCountry();
  const [countryCode, setCountryCode] = useState<string>(
    value || guessed?.id || countries[0].value,
  );

  useEffect(() => {
    onChange(countryCode || guessed?.id || countries[0].value);
  }, [onChange, countryCode, guessed, countries]);

  const renderSelectOption = ({
    option,
  }: {
    option: { value: string; label: string };
  }) => {
    return (
      <Group flex="1" gap="xs">
        <Image h={15} w={23} src={`/flags/${option.value}.svg`} />
        {countryName(option.label) || option.label}
      </Group>
    );
  };

  const realOnChange = (value: string | null) => {
    if (value) {
      setCountryCode(value);
    }
  };

  return (
    <Select
      label="Tax Country"
      withAsterisk
      description="The country for which we need to collect taxes in"
      withCheckIcon={false}
      searchable
      renderOption={renderSelectOption}
      leftSection={<Image h={15} w={23} src={`/flags/${countryCode}.svg`} />}
      onChange={realOnChange}
      data={countries}
      value={countryCode || countries[0].label}
    />
  );
};
