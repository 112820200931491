import { FC } from "react";

export const CalendarIcon: FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      width="16"
      height="16"
      strokeWidth="2"
    >
      <path d="M11.795 21h-6.795a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v4"></path>
      <path d="M18 14v4h4"></path>
      <path d="M18 18m-4 0a4 4 0 1 0 8 0a4 4 0 1 0 -8 0"></path>
      <path d="M15 3v4"></path>
      <path d="M7 3v4"></path>
      <path d="M3 11h16"></path>
    </svg>
  );
};
