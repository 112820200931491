import stripeTaxUtils from "stripe-tax-utils";
import { FC, useEffect, useState } from "react";
import { TextInput, Box } from "@mantine/core";

export interface TaxIdProps {
  value: string | undefined;
  countryCode: string | undefined;
  disabled: boolean;
  hasError: (errorPresent: boolean) => void;
  onChange: (taxId: string, stripeTaxIdType: string) => void;
}

const vatExample = (countryCode: string) => {
  const map = stripeTaxUtils.getMap();
  const c = map.find((e) => e.country === countryCode);
  return c?.example;
};

export const TaxId: FC<TaxIdProps> = ({
  value,
  countryCode,
  disabled = false,
  hasError,
  onChange,
}) => {
  const [taxId, setTaxId] = useState<string | undefined>(value);
  const [error, setError] = useState<string | null>(null);

  const example = vatExample(countryCode || "");

  useEffect(() => {
    if (taxId && countryCode) {
      if (example == undefined) {
        onChange(taxId, "");
        setError(null);
        hasError(false);
        return;
      }
      const stripeTaxIdType = stripeTaxUtils.getStripeType({
        taxId,
        country: countryCode,
      });
      if (stripeTaxIdType) {
        onChange(taxId, stripeTaxIdType);
        setError(null);
        hasError(false);
      } else {
        hasError(true);
        setError(
          "That combintation of tax id and country doesn't appear to be valid. If you believe this is an error, please contact support.",
        );
      }
    }
  }, [taxId, countryCode, onChange, hasError, example]);

  const exampleText = example == undefined ? "" : `(example: ${example}) `;

  return (
    <Box>
      <TextInput
        label="Tax ID"
        description={`Your companies tax identification number ${exampleText}`}
        disabled={disabled}
        error={error}
        value={taxId || ""}
        onChange={(event) => {
          setTaxId(event.target.value);
          setError(null);
          hasError(false);
        }}
      />
    </Box>
  );
};
