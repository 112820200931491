import { FC, useEffect, useState } from "react";
import { Select, StyleProp } from "@mantine/core";
import { CalendarIcon } from "src/ui/icons/calendar-icon";
import { AnalyticsV4Input } from "src/api/user";
import { SelectIntervalData } from "src/utility/intervals";

export interface SelectIntervalProps {
  onSelect: (input: AnalyticsV4Input) => void;
  hideLabel?: boolean;
  intervals: SelectIntervalData[];
  defaultInterval: SelectIntervalData;
  w?: StyleProp<React.CSSProperties["width"]>;
}

export const SelectInterval: FC<SelectIntervalProps> = ({
  onSelect,
  intervals,
  defaultInterval,
  hideLabel = false,
  w = undefined,
}) => {
  const [selected, setSelected] = useState<string | null>(null);

  useEffect(() => {
    if (selected == null) {
      setSelected(defaultInterval.value);
    }
  }, [selected, onSelect, defaultInterval]);

  const handleSelect = (value: string | null) => {
    setSelected(value);
    const data = intervals.find((interval) => interval.value === value);

    if (!data) {
      // This should never happen
      return;
    }

    const now = new Date();
    const endTime = data.endFn(now);
    const startTime = data.startFn(now);
    const interval = data.interval;
    value &&
      onSelect({
        startTime,
        endTime,
        interval,
      });
  };

  return (
    <Select
      label={hideLabel ? undefined : "Select interval"}
      placeholder="Select view interval "
      withCheckIcon={false}
      searchable
      w={w}
      onChange={(value) => handleSelect(value)}
      value={selected}
      data={intervals}
      leftSection={<CalendarIcon />}
    />
  );
};
