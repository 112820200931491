import { FC } from "react";
import { BaseIconProps } from "./base/icon";

export const NumberIcon: FC<BaseIconProps> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="none"
      stroke={props.fillColor ?? "currentColor"}
      width={props.w ?? 16}
      height={props.h ?? 16}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    >
      {" "}
      <path d="M3 10l2 -2v8"></path>{" "}
      <path d="M9 8h3a1 1 0 0 1 1 1v2a1 1 0 0 1 -1 1h-2a1 1 0 0 0 -1 1v2a1 1 0 0 0 1 1h3"></path>{" "}
      <path d="M17 8h2.5a1.5 1.5 0 0 1 1.5 1.5v1a1.5 1.5 0 0 1 -1.5 1.5h-1.5h1.5a1.5 1.5 0 0 1 1.5 1.5v1a1.5 1.5 0 0 1 -1.5 1.5h-2.5"></path>{" "}
    </svg>
  );
};
