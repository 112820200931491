import { FC } from "react";
import { BaseIconProps } from "./base/icon";

export const CopyIcon: FC<BaseIconProps> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="none"
      stroke={props.fillColor ?? "currentColor"}
      strokeLinecap="round"
      strokeLinejoin="round"
      width={props.w ?? 16}
      height={props.h ?? 16}
      strokeWidth="2"
    >
      <path d="M7 7m0 2.667a2.667 2.667 0 0 1 2.667 -2.667h8.666a2.667 2.667 0 0 1 2.667 2.667v8.666a2.667 2.667 0 0 1 -2.667 2.667h-8.666a2.667 2.667 0 0 1 -2.667 -2.667z"></path>{" "}
      <path d="M4.012 16.737a2.005 2.005 0 0 1 -1.012 -1.737v-10c0 -1.1 .9 -2 2 -2h10c.75 0 1.158 .385 1.5 1"></path>{" "}
    </svg>
  );
};
