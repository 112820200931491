import { FC } from "react";
import { Flex, MantineColor, Stack, Text } from "@mantine/core";
import { useTranslation } from "react-i18next";

export interface UsageTextRowProps {
  labels: string[];
  value: number;
  valueColor: MantineColor;
  compact?: boolean;
}

export const UsageTextRow: FC<UsageTextRowProps> = ({
  labels,
  value,
  valueColor,
  compact = false,
}) => {
  const { t } = useTranslation();
  return (
    <Flex direction="row" justify="space-between">
      <Stack gap={"0.1rem"}>
        <Text fw={600} size="sm">
          {labels[0]}
        </Text>
        <Text fw={600} size="sm">
          {labels[1]}
        </Text>
      </Stack>
      <Text fw={700} size="xl" c={valueColor}>
        {t(compact ? "format.compact_number" : "format.number", {
          number: value,
          formatParams: { number: { compactDisplay: "short" } },
        })}
      </Text>
    </Flex>
  );
};
