import {
  interval as dateFnsInterval,
  addDays,
  addHours,
  addMinutes,
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  eachMinuteOfInterval,
  eachHourOfInterval,
} from "date-fns";
import { Interval } from "src/api/user";

export const startOfMinute = (date: Date): Date => {
  const res = new Date(date);
  res.setUTCSeconds(0);
  res.setUTCMilliseconds(0);
  return res;
};

export const startOfCurrentMinute = (): Date => {
  return startOfMinute(new Date());
};

export const startOfHour = (date: Date): Date => {
  const res = new Date(date);
  res.setUTCMinutes(0);
  res.setUTCSeconds(0);
  res.setUTCMilliseconds(0);
  return res;
};

export const startOfCurrentHour = (): Date => {
  return startOfHour(new Date());
};

export const startOfDay = (date: Date): Date => {
  const res = new Date(date);
  res.setUTCHours(0);
  res.setUTCMinutes(0);
  res.setUTCSeconds(0);
  res.setUTCMilliseconds(0);
  return res;
};

export const startOfCurrentDay = (): Date => {
  return startOfDay(new Date());
};

export const startOfMonth = (date: Date): Date => {
  const res = new Date(date);
  res.setUTCDate(1);
  res.setUTCHours(0);
  res.setUTCMinutes(0);
  res.setUTCSeconds(0);
  res.setUTCMilliseconds(0);
  return res;
};

export const startOfCurrentMonth = (): Date => {
  return startOfMonth(new Date());
};

export const toStartOfInterval = (interval: Interval, date: Date): Date => {
  switch (interval) {
    case "day": {
      return startOfDay(date);
    }
    case "hour": {
      return startOfHour(date);
    }
    case "minute": {
      return startOfMinute(date);
    }
  }
};

export const stepsBetweenDatesInterval = (
  interval: Interval,
  startTime: Date,
  endTime: Date,
) => {
  switch (interval) {
    case "day": {
      return differenceInDays(endTime, startTime);
    }
    case "hour": {
      return differenceInHours(endTime, startTime);
    }
    case "minute": {
      return differenceInMinutes(endTime, startTime);
    }
  }
};

export const minusIntervalStep = (interval: Interval, date: Date) => {
  switch (interval) {
    case "day": {
      return addDays(date, -1);
    }
    case "hour": {
      return addHours(date, -1);
    }
    case "minute": {
      return addMinutes(date, -1);
    }
  }
};

const addDaysUTC = (date: Date, days: number) => {
  const result = new Date(date);
  result.setUTCDate(result.getUTCDate() + days);
  return result;
};

export const allStepsInInput = (
  interval: Interval,
  startTime: string,
  endTime: string,
) => {
  const int = dateFnsInterval(startTime, endTime);
  let steps = [];
  switch (interval) {
    case "day": {
      // date-fns eachDayOfInterval is broken, do it ourselves.
      // https://github.com/date-fns/date-fns/issues/3277
      let nextDate = new Date(Date.parse(startTime));
      const endDate = new Date(Date.parse(endTime));

      while (nextDate <= endDate) {
        steps.push(nextDate);
        nextDate = addDaysUTC(nextDate, 1);
      }
      break;
    }
    case "hour": {
      steps = eachHourOfInterval(int);
      break;
    }
    case "minute": {
      steps = eachMinuteOfInterval(int);
      break;
    }
  }
  // date-fns includes the end date, we don't want that
  steps.splice(steps.length - 1, 1);
  return steps;
};
