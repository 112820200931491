import { alpha, Progress } from "@mantine/core";
import { theme } from "src/ui/theme";

interface MonthlyProgressBarProps {
  progress: number | undefined;
  prognosis: number | undefined;
}

export const MonthlyProgressBar: React.FC<MonthlyProgressBarProps> = ({
  progress,
  prognosis,
}) => {
  // Limit x to 0-100
  const clamp = (x: number) => Math.max(0, Math.min(x, 100));

  const internalProgress = clamp(progress ?? 0);
  const internalPrognosis = clamp(prognosis ?? 0);

  return (
    <Progress.Root size="lg" radius="md" w="100%">
      <Progress.Section value={internalProgress} color="green" />
      {internalProgress < 100 && (
        <Progress.Section
          value={internalPrognosis}
          color={
            internalPrognosis > 100
              ? alpha(theme.colors.red[6], 0.15)
              : alpha(theme.colors.green[6], 0.15)
          }
          style={{
            border: "1px dashed var(--mantine-color-green-6)",
            borderLeft: "unset",
          }}
        />
      )}
    </Progress.Root>
  );
};
