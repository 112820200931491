import axios, { AxiosResponse, AxiosError } from "axios";
import packageInfo from "package.json";
import { recursiveToCamelCase } from "src/utility/recursive-to-camel-case";
import axiosRetry, { exponentialDelay } from "axios-retry";

export const baseURL =
  process.env.REACT_APP_MARLY_URL || "http://api_not_configured:80";

const axiosConfig = {
  baseURL,
  timeout: 60 * 1000,
  headers: {
    "X-Dwellir-App": "Dashboard",
    "X-Dwellir-Version": packageInfo.version,
  },
};
export const marlyApi = axios.create(axiosConfig);
axiosRetry(marlyApi, { retries: 5, retryDelay: exponentialDelay });

// Separate axios instance for healthChecks as we don't want the retry logic
// there.
export const healthInstance = axios.create(axiosConfig);

const unauthenticatedInterceptor = (
  error: AxiosError,
  navigateFun: () => void,
) => {
  if (error.response?.status === 401) {
    navigateFun();
  }
  return Promise.reject(error);
};

export const AxiosInterceptorsSetup = (navigateFun: () => void) => {
  marlyApi.interceptors.response.use(undefined, (error: AxiosError) =>
    unauthenticatedInterceptor(error, navigateFun),
  );
};

export interface User {
  id: string;
  email: string;
  isActive: boolean;
  isSuperuser: boolean;
  isVerified: boolean;
  isLegacy: boolean;
  createdAt: string;
  updatedAt: string;
  version: string;
  type: string;
  name: string;
  tosAgreement: string;
  profilePicture: string;
}

export interface AccessResponse {
  first_time: boolean; // Was this the first time the user gained access
}

const access = async (
  token: string,
): Promise<AxiosResponse<AccessResponse>> => {
  return await marlyApi
    .post("/v4/auth/access", { token }, { withCredentials: true })
    .catch((error) => {
      // Unknown error
      console.error("Unknown login error", error);
      throw error;
    });
};

const logout = async (): Promise<AxiosResponse<null>> => {
  return await marlyApi
    .post("/v4/auth/logout", null, {
      withCredentials: true,
    })
    .catch((error) => {
      // Unknown error
      console.error("Unknown login error", error);
      throw error;
    });
};

const currentUser = async (): Promise<AxiosResponse<User>> => {
  return await marlyApi
    .get("/v4/user", {
      withCredentials: true,
    })
    .then((backend) => {
      backend.data = recursiveToCamelCase(backend.data);
      if (backend.data.name === null) {
        backend.data.name = "";
      }
      return backend;
    });
};

const getHealthCheck = async (): Promise<AxiosResponse<null>> => {
  return await healthInstance.get("/health").catch((error) => {
    // Unknown error
    console.error("Unknown error", error);
    throw error;
  });
};

export default {
  access,
  logout,
  currentUser,
  getHealthCheck,
};
