import { useEffect, useState } from "react";
import { UsageFilter } from "./usage-filter";
import { UsageBillingCycle } from "./usage-billing-cycle";
import { UsageRps } from "./usage-rps";
import { UsageFunctionCalls } from "./usage-function-calls";
import { UsageGraph } from "./usage-graph";
import { Box, Flex } from "@mantine/core";
import { ApiKey } from "src/api/api_keys";
import { RootPanel } from "src/ui/components";
import { AnalyticsV4Input } from "src/api/user";
import { Chain } from "src/api/chain";
import { useAnalyticsV4, useCurrentSubscription } from "src/store/user";
import { useChains } from "src/store/chain";
import { ALL_CHAINS } from "src/ui/components/select-chain";
import { ALL_KEYS } from "src/ui/components/select-api-key";
import { thisMonth } from "src/utility/intervals";

export const UsagePage = () => {
  const [analyticsInput, setAnalyticsInput] =
    useState<AnalyticsV4Input>(thisMonth());
  const analytics = useAnalyticsV4(analyticsInput);
  const subscription = useCurrentSubscription();
  const chains = useChains();

  const [apiKey, setApiKey] = useState<ApiKey>(ALL_KEYS);
  const [chain, setChain] = useState<Chain>(ALL_CHAINS);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const chainId = Number(query.get("chainId"));
    if (chainId && chains.state === "fulfilled") {
      const chain = chains.data.find((chain) => chain.id === chainId);
      if (chain) {
        setChain(chain);
      }
    }
  }, [chains]);

  useEffect(() => {
    setAnalyticsInput((old) => {
      let filteredApiKey = undefined;
      let filteredDomain = undefined;

      if (apiKey.id !== ALL_KEYS.id) {
        // set filter.api keys = array of apikey.apikey
        filteredApiKey = [apiKey.apiKey];
      }

      if (chain.id !== ALL_CHAINS.id) {
        // set filter.domains  = array of domains
        let domains = undefined;
        domains = chain.https && new URL(chain.https).host;
        if (domains == null) {
          domains = chain.wss && new URL(chain.wss).host;
        }
        if (domains !== undefined) {
          filteredDomain = [domains];
        }
      }

      return {
        ...old,
        filter: { apiKeys: filteredApiKey, domains: filteredDomain },
      };
    });
  }, [apiKey, chain]);

  return (
    <RootPanel withNavbar>
      <Flex direction="column" mx="md" h="100%">
        <Flex direction="row" mb="md" w="100%" align="flex-end">
          <Box w="100%">
            <UsageFilter
              apiKey={apiKey}
              onSelectApiKey={setApiKey}
              chain={chain}
              chains={chains}
              input={{
                startTime: analyticsInput.startTime,
                endTime: analyticsInput.endTime,
                interval: analyticsInput.interval,
              }}
              onSelectChain={setChain}
              setAnalyticsInput={setAnalyticsInput}
            />
          </Box>
        </Flex>
        <Flex
          direction="row"
          mb="md"
          mih={400}
          w="100%"
          h="100%"
          align="flex-end"
        >
          <Box w="100%" h="100%">
            <UsageGraph
              h="100%"
              analyticsInput={analyticsInput}
              analytics={analytics}
              apiKey={apiKey}
              chain={chain}
              chains={chains}
            />
          </Box>
        </Flex>

        <Flex direction="row" mb="md" w="100%" align="flex-end">
          <Flex direction="row" gap="md" w="100%" justify="space-between">
            <UsageFunctionCalls
              apiKey={apiKey}
              analytics={analytics}
              w="100%"
            />
            <UsageRps
              subscription={subscription}
              input={analyticsInput}
              w="100%"
            />
            <UsageBillingCycle subscription={subscription} w="100%" />
          </Flex>
        </Flex>
      </Flex>
    </RootPanel>
  );
};
