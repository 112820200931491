import { ReactNode } from "react";
import { Badge, Box, Text } from "@mantine/core";

const ResponsiveBadge = () => {
  if (process.env.REACT_APP_SHOW_BREAKPOINT_SIZE !== "true") {
    return null;
  }
  return (
    <Box ml="md">
      <Badge color="blue" hiddenFrom="sm">
        xs
      </Badge>
      <Badge color="blue" visibleFrom="sm" hiddenFrom="md">
        sm
      </Badge>
      <Badge color="blue" visibleFrom="md" hiddenFrom="lg">
        md
      </Badge>
      <Badge color="blue" visibleFrom="lg" hiddenFrom="xl">
        lg
      </Badge>
      <Badge color="blue" visibleFrom="xl">
        xl
      </Badge>
    </Box>
  );
};

type HeaderProps = {
  text: string;
  children?: ReactNode;
};

export const Header = ({ text, children }: HeaderProps) => {
  return (
    <>
      <Text ml="md" fw={800} size="xl" visibleFrom="sm">
        {text}
      </Text>
      <Text mt="lg" ml="lg" fw={800} size="xl" hiddenFrom="sm">
        {text}
      </Text>
      <ResponsiveBadge />
      <div>{children}</div>
    </>
  );
};
