import { FC } from "react";
import ThresholdTextRow from "./threshold-text-row";
import { Flex, Paper } from "@mantine/core";
import { AnalyticsV4Row, RpsAnalytics } from "src/api/user";
import { RequestState } from "src/store/core/request_state";
import { Loading } from "src/ui/components/loading";

interface LogsNumbersProps {
  analytics: RequestState<AnalyticsV4Row[]>;
  rpsAnalytics: RequestState<RpsAnalytics>;
}

export const LogsNumbers: FC<LogsNumbersProps> = ({
  analytics,
  rpsAnalytics,
}) => {
  // TODO We need to pass the number of unsuccessfull responses and rate limited responses into this component
  return (
    <Paper p="xl" radius="lg" w={"100%"} bg="second-background">
      <Flex direction="row" w="100%" justify="space-around">
        <Loading isLoading={analytics.state === "loading"}>
          <ThresholdTextRow
            label="SUCCESSFULL RESPONSES"
            number={
              analytics.state === "fulfilled"
                ? analytics.data.reduce((acc, row) => row.responses + acc, 0)
                : 0
            }
            warningThreshold={undefined}
            errorThreshold={undefined}
          />
        </Loading>
        <Loading isLoading={analytics.state === "loading"}>
          <ThresholdTextRow
            label="UNSUCCESSFULL RESPONSES"
            number={
              analytics.state === "fulfilled"
                ? 666 /* TODO: Should show the real number */
                : 0
            }
            warningThreshold={0 /* TODO: What should the threshold be? */}
            errorThreshold={0 /* TODO: What should the threshold be? */}
          />
        </Loading>
        <Loading isLoading={rpsAnalytics.state === "loading"}>
          <ThresholdTextRow
            label="RATE LIMITED RESPONSES"
            number={
              rpsAnalytics.state === "fulfilled"
                ? rpsAnalytics.data.limitedRequests
                : 0
            }
            warningThreshold={1}
            errorThreshold={1}
          />
        </Loading>
      </Flex>
    </Paper>
  );
};
