import { AxiosResponse } from "axios";
import { marlyApi } from "./marly";
import { recursiveToCamelCase } from "src/utility/recursive-to-camel-case";
import { translateHostIfDev } from "src/utility/translate-host-if-dev";

export type Interval = "minute" | "hour" | "day";

export interface AnalyticsV4Row {
  apiKey: string;
  startTime: string;
  method: string;
  requests: number;
  responses: number;
  domain: string;
  hostSlug: string; // host in prod but in dev we translate: "api-kusama.n.dwellir.tech" -> "api-kusama"
}

export interface AnalyticsV4Filter {
  apiKeys: string[] | undefined;
  domains: string[] | undefined;
}

export interface AnalyticsV4Input {
  startTime: Date;
  endTime: Date;
  interval: Interval;
  filter?: AnalyticsV4Filter | undefined;
}

export interface RpsAnalytics {
  rps: number;
  peakRps: number;
  limitedRequests: number;
}

export interface DedicatedNodeRequest {
  chain: string;
  otherInformation: string;
}

export interface UserUpdate {
  name: string;
  tosAgreement: string;
}

const getAnalyticsV4 = async (
  input: AnalyticsV4Input,
): Promise<AxiosResponse<AnalyticsV4Row[]>> => {
  let filterInput = undefined;
  if (input.filter) {
    filterInput = {
      api_keys: input.filter.apiKeys, //eslint-disable-line camelcase
      domains: input.filter.domains,
    };
  }
  return await marlyApi
    .post(
      `/v4/user/analytics`,
      {
        start_time: input.startTime.toISOString(), //eslint-disable-line camelcase
        end_time: input.endTime.toISOString(), //eslint-disable-line camelcase
        interval: input.interval,
        filter: filterInput,
      },
      {
        withCredentials: true,
      },
    )
    .then((response) => {
      response.data = recursiveToCamelCase(response.data);
      response.data = response.data.map((row: AnalyticsV4Row) => {
        return Object.assign(row, { hostSlug: translateHostIfDev(row.domain) });
      });
      return response;
    });
};

const getRpsAnalytics = async (
  input: AnalyticsV4Input,
): Promise<AxiosResponse<RpsAnalytics>> => {
  let filterInput = undefined;
  if (input.filter) {
    filterInput = {
      api_keys: input.filter.apiKeys, //eslint-disable-line camelcase
      domains: input.filter.domains,
    };
  }
  return await marlyApi
    .post(
      `/v4/organization/analytics/rps`,
      {
        start_time: input.startTime.toISOString(), //eslint-disable-line camelcase
        end_time: input.endTime.toISOString(), //eslint-disable-line camelcase
        interval: input.interval,
        filter: filterInput,
      },
      {
        withCredentials: true,
      },
    )
    .then((response) => {
      response.data = recursiveToCamelCase(response.data);
      return response;
    });
};

const updateUser = async (input: UserUpdate): Promise<AxiosResponse<null>> => {
  return await marlyApi
    .post(
      `/v3/user`,
      {
        name: input.name,
        //eslint-disable-next-line camelcase
        tos_agreement: input.tosAgreement,
      },
      { withCredentials: true },
    )
    .catch((error) => {
      console.error("Unknown error", error);
      throw error;
    });
};

export interface CurrentSubscription {
  id: number;
  name: string;
  rateLimit: number;
  burstLimit: number;
  monthlyQuota: number | null;
  dailyQuota: number | null;
  type: string;
  version: string;
  createdAt: string;
  updatedAt: string;
  apiKeysLimit: number;
}

const getCurrentSubscription = async (): Promise<
  AxiosResponse<CurrentSubscription>
> => {
  return await marlyApi
    .get(`/v3/user/subscription`, {
      withCredentials: true,
    })
    .then((response) => {
      response.data = recursiveToCamelCase(response.data);
      return response;
    })
    .catch((error) => {
      // Unknown error
      console.error("Unknown getApiKeys error", error);
      throw error;
    });
};

export default {
  getAnalyticsV4,
  getRpsAnalytics,
  getCurrentSubscription,
  updateUser,
};
