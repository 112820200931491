import { FC } from "react";
import { IconProps } from "./base/icon";

export const AlertTriangleIcon: FC<IconProps> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      width={props.width || "24"}
      height={props.height || "24"}
      strokeWidth="2"
    >
      <path d="M12 9v4"></path>
      <path d="M10.363 3.591l-8.106 13.534a1.914 1.914 0 0 0 1.636 2.871h16.214a1.914 1.914 0 0 0 1.636 -2.87l-8.106 -13.536a1.914 1.914 0 0 0 -3.274 0z"></path>
      <path d="M12 16h.01"></path>
    </svg>
  );
};
