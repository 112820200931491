import { FC, useEffect, useState } from "react";
import { Flex, Paper, Text } from "@mantine/core";
import { ApiKey } from "src/api/api_keys";
import { Loading } from "src/ui/components/loading";
import { SelectApiKey } from "src/ui/components/select-api-key";
import { SelectChain } from "src/ui/components/select-chain";
import { AnalyticsV4Row, AnalyticsV4Input } from "src/api/user";
import { Chain } from "src/api/chain";
import { filterChainsWithData } from "src/ui/components/select-chain/filter-chains-with-data";
import {
  chainsHasDataPartition,
  HasDataPartition,
} from "src/ui/components/select-chain/chains-has-data-partition";
import { SelectInterval } from "src/ui/components/select-interval";
import { RequestState } from "src/store/core/request_state";
import { FilterIcon } from "src/ui/icons/filter-icon";
import { LogClass, SelectLogClass } from "src/ui/components/select-log-class";
import {
  PAST_15_MINUTES,
  PAST_3_DAYS,
  PAST_DAY,
  PAST_HOUR,
} from "src/utility/intervals";

interface LogsFilterProps {
  apiKey: ApiKey | null;
  apiKeys: ApiKey[] | null;
  onSelectApiKey: (key: ApiKey) => void;
  chain: Chain;
  chains: RequestState<Chain[]>;
  onSelectChain: (chain: Chain) => void;
  analytics: RequestState<AnalyticsV4Row[]>;
  setAnalyticsInput: (input: AnalyticsV4Input) => void;
  logClasses: LogClass[];
  setLogClass: (logClass: LogClass) => void;
}

export const LogsFilter: FC<LogsFilterProps> = ({
  apiKey,
  apiKeys,
  onSelectApiKey,
  chain,
  chains,
  onSelectChain,
  analytics,
  setAnalyticsInput,
  logClasses,
  setLogClass,
}) => {
  const [filteredChains, setFilteredChains] = useState<Chain[]>([]);
  const [chainsDataPartition, setChainsDataPartition] = useState<
    HasDataPartition<Chain>
  >({ hasData: [], missingData: [] });

  useEffect(() => {
    // TODO filter here should be based on if there is any logs for the chains or not
    if (chains.state === "fulfilled" && analytics.state === "fulfilled") {
      setFilteredChains(filterChainsWithData(chains.data, analytics.data));
    }
  }, [chains, analytics]);

  useEffect(() => {
    if (analytics.state === "fulfilled") {
      setChainsDataPartition(
        chainsHasDataPartition(apiKey, filteredChains, analytics.data),
      );
    }
  }, [apiKey, analytics, filteredChains]);

  return (
    <Paper>
      <Flex direction="column">
        <Flex direction="row" align="center" gap={undefined}>
          <FilterIcon />
          <Text fw={700} size="lg">
            Filters
          </Text>
        </Flex>
        <Loading
          isLoading={
            analytics.state === "loading" ||
            chains.state === "loading" ||
            apiKeys == null
          }
        >
          <Flex direction="row" gap="md" justify="space-between">
            {apiKeys != null && (
              <SelectApiKey
                selectedApiKey={apiKey}
                apiKeys={apiKeys}
                onSelect={(key: ApiKey) => onSelectApiKey(key)}
                hideLabel
                allowAllOption
                w={"100%"}
              />
            )}

            <SelectChain
              chain={chain}
              chains={chainsDataPartition}
              onSelect={onSelectChain}
              allowAllOption
              hideLabel
              w={"100%"}
            />
            <SelectLogClass
              logClasses={logClasses}
              onSelect={setLogClass}
              w="100%"
            />
            <SelectInterval
              intervals={[PAST_15_MINUTES, PAST_HOUR, PAST_DAY, PAST_3_DAYS]}
              defaultInterval={PAST_HOUR}
              onSelect={setAnalyticsInput}
              hideLabel
              w={"100%"}
            />
          </Flex>
        </Loading>
      </Flex>
    </Paper>
  );
};
