import React, { FC } from "react";
import { UpdateAvailable } from "../../update-available";
import { InternalUsersOnly } from "../../internal-users-only";
import * as classes from "./navbar.module.css";
import {
  Button,
  Burger,
  Flex,
  Accordion,
  Avatar,
  Text,
  Box,
} from "@mantine/core";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { PageRoute } from "src/utility/utils";
import { ChartIcon } from "src/ui/icons/chart-icon";
import { KeyIcon } from "src/ui/icons/key-icon";
import { SupportIcon } from "src/ui/icons/support-icon";
import { DedicatedNodesIcon } from "src/ui/icons/dedicated-nodes-icon";
import { GearIcon } from "src/ui/icons/gear-icon";
import { DwellirLogo } from "src/ui/icons/dwellir-logo";
import { PhoneIcon } from "src/ui/icons/phone-icon";
import { SignOutIcon } from "src/ui/icons/sign-out-icon";
import { EndpointIcon } from "src/ui/icons/endpoint-icon";
import { useOutsetaAccount } from "src/store/organization";
import { useUser } from "src/store/user";
import { ProfileIcon } from "src/ui/icons/profile-icon";
import { AlertTriangleIcon } from "src/ui/icons/alert-triangle-icon";

type NavbarProps = {
  onBurgerClick?: () => void;
  disableButtons?: boolean;
};

export const Navbar = ({
  onBurgerClick,
  disableButtons = false,
}: NavbarProps) => {
  const { t } = useTranslation();
  return (
    <Flex h="100%" direction="column" bg="second-background">
      <Flex className={classes.header} justify="space-between" align="center">
        <Burger opened={true} onClick={onBurgerClick} hiddenFrom="sm" />
        <Link to={PageRoute.DASHBOARD} style={{ margin: "auto" }}>
          <DwellirLogo />
        </Link>
      </Flex>
      <Flex h="100%" p="md" direction="column" justify="space-between">
        <Flex direction="column">
          <NavbarButton
            id="endpoints-button"
            to={PageRoute.ENDPOINTS}
            disabled={disableButtons}
            text={t("root_panel.navbar.endpoints_button")}
            icon={<EndpointIcon />}
          />
          <NavbarButton
            id="dedicated-nodes-button"
            to={PageRoute.DEDICATED_NODES}
            disabled={disableButtons}
            text={t("root_panel.navbar.dedicated_nodes_button")}
            icon={<DedicatedNodesIcon />}
          />
          <NavbarButton
            id="usage-button"
            to={PageRoute.USAGE}
            disabled={disableButtons}
            text={t("root_panel.navbar.usage_button")}
            icon={<ChartIcon />}
          />
          <InternalUsersOnly>
            <NavbarButton
              id="logs-button"
              to={PageRoute.LOGS}
              disabled={disableButtons}
              text={t("root_panel.navbar.logs_button")}
              icon={<AlertTriangleIcon height={"1em"} width={"1em"} />}
            />
          </InternalUsersOnly>
          <NavbarButton
            id="api-keys-button"
            to={PageRoute.API_KEYS}
            disabled={disableButtons}
            text={t("root_panel.navbar.api_keys_buton")}
            icon={<KeyIcon />}
          />
        </Flex>
        <Flex direction="column">
          <SupportButton
            disabled={disableButtons}
            text={t("root_panel.navbar.support_button")}
            icon={<SupportIcon />}
          />
          <ExternalLinkButton
            id="call-us-button"
            to={"https://cal.com/dwellir/feedback"}
            disabled={disableButtons}
            text={t("root_panel.navbar.call_us_button")}
            icon={<PhoneIcon />}
          />
          <AccountButton />
          <UpdateAvailable />
        </Flex>
      </Flex>
    </Flex>
  );
};

type NavbarButtonProps = {
  id: string;
  text: string;
  to: string;
  disabled: boolean;
  icon: React.ReactElement;
};

type SupportButtonProps = {
  text: string;
  disabled: boolean;
  icon: React.ReactElement;
};

const NavbarButton = ({ id, text, to, disabled, icon }: NavbarButtonProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const isActive = location.pathname === to;

  const buttonClass = `${classes.button} ${
    isActive ? classes.active : classes["non-active"]
  }`;

  return (
    <Button
      id={id}
      className={buttonClass}
      variant="light"
      size="xs"
      disabled={disabled}
      justify="left"
      mb="md"
      onClick={() => navigate(to)}
    >
      <span style={{ marginRight: 10 }}>{icon}</span> {text}
    </Button>
  );
};

const SupportButton = ({ text, disabled, icon }: SupportButtonProps) => {
  const navigate = useNavigate();
  const buttonClass = `${classes.button} ${classes["non-active"]}`;

  return (
    <Button
      id="support-button"
      className={buttonClass}
      variant="default"
      size="xs"
      disabled={disabled}
      justify="left"
      mb="md"
      onClick={() => navigate(PageRoute.SUPPORT)}
    >
      {icon && <span style={{ marginRight: 10 }}>{icon}</span>} {text}
    </Button>
  );
};

const AccountButton = () => {
  const user = useUser();
  const outsetaAccount = useOutsetaAccount();

  return (
    <Accordion styles={{ label: { paddingTop: 0 } }} variant="" w="100%">
      <Accordion.Item value="photos" w="100%">
        <Accordion.Control w="100%">
          <Flex direction="row" align="center">
            {user.state === "fulfilled" && user.data.profilePicture ? (
              <Avatar
                radius="xl"
                src={user.state === "fulfilled" ? user.data.profilePicture : ""}
                size="16"
                style={{ marginRight: 10 }}
              />
            ) : (
              <Box mr="xs">
                <ProfileIcon />
              </Box>
            )}
            <Flex direction="column">
              <Text size="sm" fw={400}>
                {user.state === "fulfilled"
                  ? (user.data.name ?? user.data.email)
                  : ""}
              </Text>
              <Text fw={500} c="dimmed">
                {outsetaAccount.state === "fulfilled" &&
                  outsetaAccount.data.name}
              </Text>
            </Flex>
          </Flex>
        </Accordion.Control>
        <Accordion.Panel pl={10}>
          <ExternalLinkButton
            id="profile-button"
            to={
              "https://dwellir.outseta.com/profile?tab=profile#o-authenticated"
            }
            disabled={false}
            text={"Settings"}
            icon={<GearIcon />}
          />
          <NavbarButton
            id="signout-button"
            to={PageRoute.LOGOUT}
            disabled={false}
            text={"Sign Out"}
            icon={<SignOutIcon />}
          />
        </Accordion.Panel>
      </Accordion.Item>
    </Accordion>
  );
};

type ExternalLinkButtonProps = {
  id: string;
  text: string;
  to: string;
  disabled: boolean;
  icon: React.ReactElement;
};

const ExternalLinkButton: FC<ExternalLinkButtonProps> = ({
  id,
  to,
  text,
  disabled,
  icon,
}) => {
  const buttonClass = `${classes.button} ${classes["non-active"]}`;

  return (
    <Button
      id={id}
      component="a"
      href={to}
      target="_blank"
      className={buttonClass}
      variant="default"
      size="xs"
      disabled={disabled}
      justify="left"
      mb="md"
    >
      {icon && <span style={{ marginRight: 10 }}>{icon}</span>} {text}
    </Button>
  );
};
