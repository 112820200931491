import { useEffect } from "react";
import api, { AccessResponse, User } from "../api/marly";
import userApi, {
  AnalyticsV4Row,
  AnalyticsV4Input,
  CurrentSubscription,
  RpsAnalytics,
  UserUpdate,
} from "../api/user";
import {
  LOADING_STATE,
  Error,
  RequestState,
  rejectedState,
  fulfilledState,
  EMPTY_STATE,
} from "./core/request_state";
import { cookieSelector, isAuthenticated } from "./authentication";
import { useAppDispatch, useAppSelector } from "src/core/hooks";
import { RootState } from "src/store";
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { useCookies } from "react-cookie";

export interface UserState {
  user: RequestState<User>;
  access: RequestState<AccessResponse>; // Track access request
  userUpdate: RequestState<null>;
  analyticsV4: Record<string, RequestState<AnalyticsV4Row[]>>;
  rpsAnalytics: Record<string, RequestState<RpsAnalytics>>;
  subscription: RequestState<CurrentSubscription>;
}

const initialState: UserState = {
  user: EMPTY_STATE,
  access: EMPTY_STATE,
  userUpdate: EMPTY_STATE,
  analyticsV4: {},
  rpsAnalytics: {},
  subscription: EMPTY_STATE,
};

const analyticsV4Key = (input: AnalyticsV4Input): string => {
  // Sort the keys to make sure the key is the same for the same input even
  // if the object order is different
  const entries = Object.entries(input).sort();
  let analyticsKey = "";
  for (const [key, value] of entries) {
    if (key === "filter" && value !== undefined) {
      const filterEntries = Object.entries(value).sort();
      for (const [key, value] of filterEntries) {
        analyticsKey += `${key}${value}`;
      }
    } else {
      analyticsKey += `${key}${value}`;
    }
  }
  return analyticsKey;
};

export const loadCurrentUser = createAsyncThunk(
  "user/current",
  async (_input, { rejectWithValue }) => {
    try {
      const response = await api.currentUser();
      return response.data;
    } catch (error: unknown) {
      // Unknown error
      console.error("Unknown error", error);
      return rejectWithValue({ type: "unknown", serverMessage: "" });
    }
  },
);

export const access = createAsyncThunk(
  "auth/access",
  async (token: string, { rejectWithValue }) => {
    try {
      const response = await api.access(token);
      // We get no body, only a cookie
      return response.data;
    } catch (error: unknown) {
      // Unknown error
      console.error("Unknown error", error);
      return rejectWithValue({ type: "unknown", serverMessage: "" });
    }
  },
);

export const updateUser = createAsyncThunk(
  "user/update",
  async (input: UserUpdate, { rejectWithValue }) => {
    try {
      await userApi.updateUser(input);
      // We get no usable response
      return null;
    } catch (error: unknown) {
      // Unknown error
      console.error("Unknown error", error);
      return rejectWithValue({ type: "unknown", serverMessage: "" });
    }
  },
);

export const getAnalyticsV4 = createAsyncThunk(
  "user/getAnalyticsV4",
  async (input: AnalyticsV4Input, { rejectWithValue }) => {
    try {
      const response = await userApi.getAnalyticsV4(input);
      return response.data;
    } catch (error: unknown) {
      // Unknown error
      console.error("Unknown getAnalytics error", error);
      return rejectWithValue({ type: "unknown", serverMessage: "" });
    }
  },
);

export const getRpsAnalytics = createAsyncThunk(
  "user/getRpsAnalytics",
  async (input: AnalyticsV4Input, { rejectWithValue }) => {
    try {
      const response = await userApi.getRpsAnalytics(input);
      return response.data;
    } catch (error: unknown) {
      // Unknown error
      console.error("Unknown error", error);
      return rejectWithValue({ type: "unknown", serverMessage: "" });
    }
  },
);
export const getSubscription = createAsyncThunk(
  "user/getSubscription",
  async (_, { rejectWithValue }) => {
    try {
      const response = await userApi.getCurrentSubscription();
      return response.data;
    } catch (error: unknown) {
      // Unknown error
      console.error("Unknown getSubscription error", error);
      return rejectWithValue({ type: "unknown", serverMessage: "" });
    }
  },
);

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    reloadCurrentSubscription(state) {
      state.subscription = EMPTY_STATE;
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(loadCurrentUser.pending, (state, _action) => {
      state.user = LOADING_STATE;
    });
    builder.addCase(
      loadCurrentUser.rejected,
      (state, action: PayloadAction<unknown>) => {
        const error = action.payload as Error;

        state.user = rejectedState(error);
      },
    );
    builder.addCase(
      loadCurrentUser.fulfilled,
      (state, action: PayloadAction<User>) => {
        state.user = fulfilledState(action.payload);
      },
    );

    builder.addCase(access.pending, (state, _action) => {
      state.access = LOADING_STATE;
    });
    builder.addCase(
      access.rejected,
      (state, action: PayloadAction<unknown>) => {
        const error = action.payload as Error;

        state.access = rejectedState(error);
      },
    );
    builder.addCase(
      access.fulfilled,
      (state, action: PayloadAction<AccessResponse>) => {
        state.access = fulfilledState(action.payload);
        state.user = EMPTY_STATE; // Reset the current user to force a refresh
      },
    );

    builder.addCase(updateUser.pending, (state, _action) => {
      state.userUpdate = LOADING_STATE;
    });
    builder.addCase(
      updateUser.rejected,
      (state, action: PayloadAction<unknown>) => {
        const error = action.payload as Error;

        state.userUpdate = rejectedState(error);
      },
    );
    builder.addCase(
      updateUser.fulfilled,
      (state, action: PayloadAction<null>) => {
        state.userUpdate = fulfilledState(action.payload);
        // Current update user interface does not return the new user so mark it as EMPTY to force a refresh when needed?
        state.user = EMPTY_STATE;
      },
    );

    builder.addCase(getAnalyticsV4.pending, (state, action) => {
      state.analyticsV4[analyticsV4Key(action.meta.arg)] = LOADING_STATE;
    });
    builder.addCase(getAnalyticsV4.rejected, (state, action) => {
      const error = action.payload as Error;
      state.analyticsV4[analyticsV4Key(action.meta.arg)] = rejectedState(error);
    });
    builder.addCase(getAnalyticsV4.fulfilled, (state, action) => {
      state.analyticsV4[analyticsV4Key(action.meta.arg)] = fulfilledState(
        action.payload,
      );
    });

    builder.addCase(getRpsAnalytics.pending, (state, action) => {
      state.rpsAnalytics[analyticsV4Key(action.meta.arg)] = LOADING_STATE;
    });
    builder.addCase(getRpsAnalytics.rejected, (state, action) => {
      const error = action.payload as Error;

      state.rpsAnalytics[analyticsV4Key(action.meta.arg)] =
        rejectedState(error);
    });
    builder.addCase(getRpsAnalytics.fulfilled, (state, action) => {
      state.rpsAnalytics[analyticsV4Key(action.meta.arg)] = fulfilledState(
        action.payload,
      );
    });

    builder.addCase(getSubscription.pending, (state, _action) => {
      state.subscription = LOADING_STATE;
    });
    builder.addCase(
      getSubscription.rejected,
      (state, action: PayloadAction<unknown>) => {
        const error = action.payload as Error;

        state.subscription = rejectedState(error);
      },
    );
    builder.addCase(
      getSubscription.fulfilled,
      (state, action: PayloadAction<CurrentSubscription>) => {
        state.subscription = fulfilledState(action.payload);
      },
    );
  },
});

// Action creators are generated for each case reducer function
// export const { } = authSlice.actions;

/* Selectors */
export const selectLoading = (state: RootState): boolean => state.user.loading;
export const selectUser = (state: RootState): User | null => state.user.user;
export const selectError = (state: RootState): Error | null => state.user.error;
const selectUserState = (state: RootState): UserState => state.user;
export const selectAccessRequestState = (
  state: RootState,
): RequestState<AccessResponse> => state.user.access;
const selectUserSubscription = (
  state: RootState,
): RequestState<CurrentSubscription> => state.user.subscription;

export const useUser = (): RequestState<User> => {
  const dispatch = useAppDispatch();
  const userState = useAppSelector(selectUserState);
  const [cookies] = useCookies(cookieSelector);

  useEffect(() => {
    if (userState.user === EMPTY_STATE && isAuthenticated(cookies)) {
      dispatch(loadCurrentUser());
    }
  }, [cookies, userState, dispatch]);

  // TODO We should probably handle errors in a generic way?
  // Throw here and have a error boundary that handles it?

  return userState.user;
};

export const useAnalyticsV4 = (
  input: AnalyticsV4Input,
): RequestState<AnalyticsV4Row[]> => {
  const dispatch = useAppDispatch();
  const userState = useAppSelector(selectUserState);
  const key = analyticsV4Key(input);

  useEffect(() => {
    const state =
      key in userState.analyticsV4 ? userState.analyticsV4[key] : EMPTY_STATE;
    if (state.state === "empty") {
      dispatch(getAnalyticsV4(input));
    }
  }, [key, userState, input, dispatch]);

  return key in userState.analyticsV4
    ? userState.analyticsV4[key]
    : EMPTY_STATE;
};

export const useRpsAnalytics = (
  input: AnalyticsV4Input,
): RequestState<RpsAnalytics> => {
  const dispatch = useAppDispatch();
  const userState = useAppSelector(selectUserState);
  const key = analyticsV4Key(input);

  useEffect(() => {
    const state =
      key in userState.rpsAnalytics ? userState.rpsAnalytics[key] : EMPTY_STATE;
    if (state.state === "empty") {
      dispatch(getRpsAnalytics(input));
    }
  }, [dispatch, userState, key, input]);

  // TODO We should probably handle errors in a generic way?
  // Throw here and have a error boundary that handles it?

  return userState.rpsAnalytics[key] ?? EMPTY_STATE;
};

export const useCurrentSubscription = (): RequestState<CurrentSubscription> => {
  const dispatch = useAppDispatch();
  const subscription = useAppSelector(selectUserSubscription);

  useEffect(() => {
    if (subscription.state == "empty") {
      dispatch(getSubscription());
    }
  }, [subscription, dispatch]);

  // TODO We should probably handle errors in a generic way?
  // Throw here and have a error boundary that handles it?

  return subscription;
};

export const { reloadCurrentSubscription } = userSlice.actions;
export default userSlice.reducer;
