import { addDays, addHours, addMonths, addMinutes } from "date-fns";
import { Interval } from "src/api/user";
import {
  startOfDay,
  startOfHour,
  startOfMinute,
  startOfMonth,
} from "src/utility/date";

export interface SelectIntervalData {
  label: string;
  value: string;
  startFn: (date: Date) => Date;
  endFn: (date: Date) => Date;
  interval: Interval;
}

export const PAST_5_MINUTES = {
  label: "Past 5 minutes",
  value: "5m",
  startFn: (date: Date) => startOfMinute(addMinutes(new Date(date), -5)),
  endFn: (date: Date) => startOfMinute(addMinutes(new Date(date), 1)),
  interval: "minute" as Interval,
};

export const PAST_15_MINUTES = {
  label: "Past 15 minutes",
  value: "15m",
  startFn: (date: Date) => startOfMinute(addMinutes(new Date(date), -15)),
  endFn: (date: Date) => startOfMinute(addMinutes(new Date(date), 1)),
  interval: "minute" as Interval,
};

export const PAST_30_MINUTES = {
  label: "Past 30 minutes",
  value: "30m",
  startFn: (date: Date) => startOfMinute(addMinutes(new Date(date), -30)),
  endFn: (date: Date) => startOfMinute(addMinutes(new Date(date), 1)),
  interval: "minute" as Interval,
};

export const CURRENT_HOUR = {
  label: "Current Hour",
  value: "1h",
  startFn: (date: Date) => startOfHour(new Date(date)),
  endFn: (date: Date) => startOfHour(addHours(new Date(date), 1)),
  interval: "minute" as Interval,
};

export const PAST_HOUR = {
  label: "Past Hour",
  value: "past1h",
  startFn: (date: Date) => startOfMinute(addMinutes(new Date(date), -60)),
  endFn: (date: Date) => startOfHour(addHours(new Date(date), 1)),
  interval: "minute" as Interval,
};

export const PAST_8_HOURS = {
  label: "Past 8 hours",
  value: "8h",
  startFn: (date: Date) => startOfHour(addHours(new Date(date), -8)),
  endFn: (date: Date) => startOfHour(addHours(new Date(date), 1)),
  interval: "hour" as Interval,
};

export const TODAY = {
  label: "Today",
  value: "1d",
  startFn: (date: Date) => startOfDay(new Date(date)),
  endFn: (date: Date) => startOfDay(addDays(new Date(date), 1)),
  interval: "hour" as Interval,
};

export const PAST_DAY = {
  label: "Past day",
  value: "past1d",
  startFn: (date: Date) => startOfHour(addHours(new Date(date), -24)),
  endFn: (date: Date) => startOfDay(addDays(new Date(date), 1)),
  interval: "day" as Interval,
};

export const PAST_3_DAYS = {
  label: "Past 3 days",
  value: "3d",
  startFn: (date: Date) => startOfDay(addDays(new Date(date), -3)),
  endFn: (date: Date) => startOfDay(addDays(new Date(date), 1)),
  interval: "day" as Interval,
};

export const THIS_MONTH = {
  label: "This Month",
  value: "this_month",
  startFn: (date: Date) => startOfMonth(new Date(date)),
  endFn: (date: Date) => startOfMonth(addMonths(new Date(date), 1)),
  interval: "day" as Interval,
};

export const PAST_30_DAYS = {
  label: "Past 30 days",
  value: "30d",
  startFn: (date: Date) => startOfDay(addDays(new Date(date), -30)),
  endFn: (date: Date) => startOfDay(addDays(new Date(date), 1)),
  interval: "day" as Interval,
};

export const PAST_7_DAYS = {
  label: "Past 7 days",
  value: "7d",
  startFn: (date: Date) => startOfDay(addDays(new Date(date), -7)),
  endFn: (date: Date) => startOfDay(addDays(new Date(date), 1)),
  interval: "day" as Interval,
};

export const PAST_MONTH = {
  label: "Past month",
  value: "1m",
  startFn: (date: Date) => startOfMonth(addMonths(new Date(date), -1)),
  endFn: (date: Date) => startOfMonth(new Date(date)),
  interval: "day" as Interval,
};

export const PAST_90_DAYS = {
  label: "Past 90 days",
  value: "3m",
  startFn: (date: Date) => startOfDay(addDays(new Date(date), -90)),
  endFn: (date: Date) => startOfDay(addDays(new Date(date), 1)),
  interval: "day" as Interval,
};

export const thisMonth = () => {
  const now = new Date();
  return {
    interval: THIS_MONTH.interval,
    startTime: THIS_MONTH.startFn(now),
    endTime: THIS_MONTH.endFn(now),
  };
};

export const past30Days = () => {
  const now = new Date();
  return {
    interval: PAST_30_DAYS.interval,
    startTime: PAST_30_DAYS.startFn(now),
    endTime: PAST_30_DAYS.endFn(now),
  };
};
