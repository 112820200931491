import { useEffect, useState } from "react";
import { LogsFilter } from "./logs-filter";
import { LogsNumbers } from "./logs-numbers";
import { LogsTable, LogRow } from "./logs-table";
import { ALL_LOGS, LogClass } from "src/ui/components/select-log-class";
import { Box, Flex, Paper } from "@mantine/core";
import { RootPanel } from "src/ui/components";
import { ApiKey } from "src/api/api_keys";
import { AnalyticsV4Input } from "src/api/user";
import { Chain } from "src/api/chain";
import { useAnalyticsV4, useRpsAnalytics } from "src/store/user";
import { useChains } from "src/store/chain";
import { ALL_CHAINS } from "src/ui/components/select-chain";
import { ALL_KEYS } from "src/ui/components/select-api-key";
import { useUserApiKeys } from "src/store/api_keys";
import { translateHostIfDev } from "src/utility/translate-host-if-dev";
import { thisMonth } from "src/utility/intervals";

export const LogsPage = () => {
  const apiKeys = useUserApiKeys();
  const [analyticsInput, setAnalyticsInput] =
    useState<AnalyticsV4Input>(thisMonth());
  const analytics = useAnalyticsV4(analyticsInput);
  const rpsAnalytics = useRpsAnalytics(analyticsInput);
  const chains = useChains();

  const [apiKey, setApiKey] = useState<ApiKey>(ALL_KEYS);
  const [chain, setChain] = useState<Chain>(ALL_CHAINS);
  const [logClass, setLogClass] = useState<LogClass>(ALL_LOGS);

  const [logClasses, setLogClasses] = useState<LogClass[]>([]);
  const [logs, setLogs] = useState<LogRow[]>([]);

  // TODO we should request the logs from the backend, similar to how we request the analytics
  useEffect(() => {
    if (apiKeys && apiKeys.length >= 1) {
      const rows: LogRow[] = [
        {
          date: new Date(Date.UTC(2025, 2, 12, 14, 33, 22)),
          apiKey: apiKeys[0].apiKey,
          domain: "https://api-kusama.dwellir.com",
          request: {
            method: "POST",
            url: `https://api-immutable-zkevm-mainnet.n.dwellir.com/${apiKeys[0].apiKey}`,
            rpcMethod: "eth_getBlock",
            headers: {
              Host: "api-immutable-zkevm-mainnet.n.dwellir.com",
              "User-Agent": "curl/8.5.0",
              "Content-Type": "application/json",
              "Content-Length": "50",
            },
            body: '{"id": 1, "jsonrpc": "2.0", "method": "eth_getBlock", "params": ["0x1", true]}',
          },
          response: {
            statusCode: 429,
            statusLabel: "Too many requests",
            headers: {
              "HTTP/2": "429",
              "access-control-allow-origin": "*",
              "Content-Type": "application/json",
              date: "Fri, 14 Feb 2025 14:33:22 GMT",
              "Content-Length": "40",
              "strict-transport-security":
                "max-age=31536000; includeSubDomains; preload",
            },
            body: "Too many requests",
          },
        },
        {
          date: new Date(Date.UTC(2025, 2, 12, 14, 33, 24)),
          method: "eth_getBlock",
          apiKey: apiKeys[1 % apiKeys.length].apiKey,
          domain: "https://api-polkadot.dwellir.com",
          request: {
            method: "POST",
            rpcMethod: "eth_getBlock",
            url: `https://api-immutable-zkevm-mainnet.n.dwellir.com/${
              apiKeys[1 % apiKeys.length].apiKey
            }`,
            headers: {
              Host: "api-immutable-zkevm-mainnet.n.dwellir.com",
              "User-Agent": "curl/8.5.0",
              "Content-Type": "application/json",
              "Content-Length": "50",
            },
            body: '{"id": 1, "jsonrpc": "2.0", "method": "eth_getBlock", "params": ["0x1", true]}',
          },
          response: {
            statusCode: 403,
            statusLabel: "Forbidden",
            headers: {
              "HTTP/2": "403",
              "access-control-allow-origin": "*",
              "Content-Type": "application/json",
              date: "Fri, 14 Feb 2025 14:33:22 GMT",
              "Content-Length": "40",
              "strict-transport-security":
                "max-age=31536000; includeSubDomains; preload",
            },
            body: "Forbidden",
          },
        },
        {
          date: new Date(Date.UTC(2025, 3, 12, 12, 12, 12)),
          apiKey: apiKeys[2 % apiKeys.length].apiKey,
          domain: "wss://api-kusama.dwellir.com",
          request: {
            method: "GET",
            url: `wss://api-kusama.dwellir.com/${apiKeys[2 % apiKeys.length].apiKey}`,
            headers: {
              Host: "api-kusama.dwellir.com",
              "User-Agent": "wsstat/1.1.0",
              Connection: "Upgrade",
              Upgrade: "websocket",
              "Sec-WebSocket-Key": "dGhlIHNhbXBsZSBub25jZQ==",
              "Sec-WebSocket-Version": "13",
            },
          },
          response: {
            statusCode: 101,
            statusLabel: "Switching Protocols",
            headers: {
              Date: "Wed, 12 Mar 2025 12:12:22 GMT",
              Connection: "Upgrade",
              Upgrade: "websocket",
              "Sec-WebSocket-Accept": "s3pPLMBiTxaQ9kYGzzhZRbK+xOo=",
            },
          },
        },
        {
          date: new Date(Date.UTC(2025, 3, 12, 12, 12, 13)),
          apiKey: apiKeys[2 % apiKeys.length].apiKey,
          domain: "wss://api-kusama.dwellir.com",
          url: `wss://api-kusama.dwellir.com/${apiKeys[2 % apiKeys.length].apiKey}`,
          request: {
            rpcMethod: "some_unknown_method",
            timestamp: new Date(
              Date.UTC(2025, 3, 12, 12, 12, 12),
            ).toISOString(),
            body: '{"id": 1, "jsonrpc": "2.0", "method": "some_unknown_method", "params": ["0x1", true]}',
          },
          response: {
            timestamp: new Date(
              Date.UTC(2025, 3, 12, 12, 12, 13),
            ).toISOString(),
            statusCode: -32601,
            statusLabel: "Method not found",
            body: '{"jsonrpc":"2.0","id":1,"error":{"code":-32601,"message":"Method not found"}}',
          },
        },
      ]
        .filter((row) => {
          if (apiKey.apiKey === ALL_KEYS.apiKey) {
            return true;
          } else {
            return row.apiKey === apiKey.apiKey;
          }
        })
        .filter((row) => {
          if (chain.id === ALL_CHAINS.id) {
            return true;
          } else {
            return chain.networks.some(
              (network) =>
                translateHostIfDev(row.domain) ===
                translateHostIfDev(network.https),
            );
          }
        });

      setLogs(rows);
    }
  }, [apiKey, chain, apiKeys]);

  useEffect(() => {
    const uniqueLogClasses = logs.reduce(
      (acc, log) => acc.set(log.response.statusCode, log.response.statusLabel),
      new Map(),
    );
    const logClasses = Array.from(uniqueLogClasses).map(([id, name]) => ({
      id,
      name,
    }));
    setLogClasses(logClasses);
  }, [logs]);

  return (
    <RootPanel withNavbar>
      <Flex direction="column" m={{ base: "xl", t: "sm" }} h="100%" gap="lg">
        <Flex direction="row" w="100%" align="flex-end">
          <Box w="100%">
            <LogsFilter
              apiKey={apiKey}
              apiKeys={apiKeys}
              onSelectApiKey={setApiKey}
              chain={chain}
              chains={chains}
              onSelectChain={setChain}
              analytics={analytics}
              setAnalyticsInput={setAnalyticsInput}
              logClasses={logClasses}
              setLogClass={setLogClass}
            />
          </Box>
        </Flex>
        <LogsNumbers analytics={analytics} rpsAnalytics={rpsAnalytics} />
        <Paper p="xl" radius="lg" mb="lg" w={"100%"} bg="second-background">
          <Flex direction="row">
            <LogsTable chains={chains} logs={logs} logClass={logClass} />
          </Flex>
        </Paper>
      </Flex>
    </RootPanel>
  );
};
