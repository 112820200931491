import { Button } from "../buttons";
import { Flex, Paper, Text } from "@mantine/core";
import { FlashIcon } from "src/ui/icons/flash-icon";

export const DailyQuotaDepleted = () => {
  return (
    <Paper
      shadow="sm"
      miw={"400px"}
      p="md"
      m="md"
      radius="md"
      withBorder
      h="90%"
    >
      <Flex direction="column" gap="md">
        <Text size="md">You have exceeded your daily usage.</Text>
        <Text size="sm">
          You will be allowed to make additional requests tomorrow.
        </Text>
        <Text size="sm">
          Need more now? Upgrade to a higher tier for unlimited daily requests
          and generous monthly limits.
        </Text>
        <Button
          onClick={() => alert("Should open Outseta upgrade modal")}
          text={"Upgrade now!"}
          icon={<FlashIcon fillColor="white" />}
          style={{ height: "32px" }}
        />
      </Flex>
    </Paper>
  );
};
