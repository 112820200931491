import { useEffect } from "react";
import {
  createBrowserRouter,
  Navigate,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { useCookies } from "react-cookie";
import { PageRoute } from "src/utility/utils";
import { Login } from "src/ui/pages/login";
import { Dashboard } from "src/ui/pages/dashboard";
import { Register } from "src/ui/pages/register";
import { ApiKeysPage } from "src/ui/pages/api_keys";
import { cookieSelector, isAuthenticated } from "src/store/authentication";
import { Logout } from "src/ui/pages/logout";
import { AxiosInterceptorsSetup } from "src/api/marly";
import { EndpointsPage } from "src/ui/pages/endpoints";
import { VerifyPage } from "src/ui/pages/verify";
import { DedicatedNodes } from "src/ui/pages/dedicated_nodes";
import { Support } from "src/ui/pages/support";
import { AccessPage } from "src/ui/pages/access";
import { RequiredDataPage } from "src/ui/pages/required-data";
import { UsagePage } from "src/ui/pages/usage";
import { LogsPage } from "src/ui/pages/logs";

type ProtectedRouteProps = {
  children: React.ReactNode;
};

export const AxiosInterceptorNavigate = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const location = document.location;
    const query = new URLSearchParams();
    query.set("from", location.pathname + location.search);

    const navigateFun = () => {
      // We must check against document.location.pathname here to ensure that we
      // get the current location. the useLocation location will be captured and
      // kept static in the closure which causes a logout <-> login loop if
      // the logout call fails on the logout page.
      if (document.location.pathname !== PageRoute.LOGOUT) {
        navigate(PageRoute.LOGOUT + `?${query.toString()}`, {
          replace: true,
        });
      }
    };
    AxiosInterceptorsSetup(navigateFun);
  }, [navigate]);

  return <></>;
};

// Protected Route Wrapper
const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  const [cookies] = useCookies(cookieSelector);
  const location = useLocation();

  if (isAuthenticated(cookies) === false) {
    if (location.pathname === PageRoute.LOGOUT) {
      // Don't create a Login -> Logout -> Login redirect loop
      return <Navigate to={PageRoute.LOGIN} replace />;
    }
    return <Navigate to={PageRoute.LOGIN} replace state={{ from: location }} />;
  }

  return (
    <>
      {/* If we ever encounter a 401 error this interceptor will redirect us to
      the login page before continuing. */}
      <AxiosInterceptorNavigate />
      {children}
    </>
  );
};

export const router = createBrowserRouter(
  [
    {
      path: PageRoute.INDEX,
      element: (
        <ProtectedRoute>
          <Navigate to={"dashboard"} />
        </ProtectedRoute>
      ),
      index: true,
    },
    {
      path: PageRoute.LOGIN,
      element: <Login />,
    },
    {
      path: PageRoute.LOGOUT,
      element: <Logout />,
    },
    {
      path: PageRoute.DASHBOARD,
      element: (
        <ProtectedRoute>
          <Dashboard />
        </ProtectedRoute>
      ),
    },
    {
      path: PageRoute.ENDPOINTS,
      element: (
        <ProtectedRoute>
          <EndpointsPage />
        </ProtectedRoute>
      ),
    },
    {
      path: PageRoute.API_KEYS,
      element: (
        <ProtectedRoute>
          <ApiKeysPage />
        </ProtectedRoute>
      ),
    },
    {
      path: PageRoute.VERIFY,
      element: <VerifyPage />,
    },
    {
      path: PageRoute.ACCESS,
      element: <AccessPage />,
    },
    {
      path: PageRoute.REQUIRED_DATA,
      element: <RequiredDataPage />,
    },
    {
      path: PageRoute.REGISTER,
      element: <Register />,
    },
    {
      path: PageRoute.DEDICATED_NODES,
      element: (
        <ProtectedRoute>
          <DedicatedNodes />
        </ProtectedRoute>
      ),
    },
    {
      path: PageRoute.USAGE,
      element: (
        <ProtectedRoute>
          <UsagePage />
        </ProtectedRoute>
      ),
    },
    {
      path: PageRoute.SUPPORT,
      element: (
        <ProtectedRoute>
          <Support />
        </ProtectedRoute>
      ),
    },
    {
      path: PageRoute.LOGS,
      element: (
        <ProtectedRoute>
          <LogsPage />
        </ProtectedRoute>
      ),
    },
  ],
  { basename: getEnvironmentSpecificBasename(window.location.pathname) },
);

// This function is used to determine the basename of the router based on the environment
// If we're running in a subfolder, we need to set the basename to that subfolder
// Currently we have one environment that uses a subfolder by the name of /dwellir
// Otherwise, we assume that we run in the root folder
function getEnvironmentSpecificBasename(path: string) {
  if (path.startsWith("/dwellir")) {
    return "/dwellir/";
  }

  return "";
}
