import { AxiosResponse } from "axios";
import { marlyApi } from "./marly";
import { translateHostIfDev } from "src/utility/translate-host-if-dev";

export type Network = {
  id: number;
  name: string;
  nodeType: string;
  https: string;
  wss: string;
  hostSlug: string; // host in prod but in dev we translate: "api-kusama.n.dwellir.tech" -> "api-kusama"
};

export interface Chain {
  id: number;
  name: string;
  imageUrl: string;
  networks: Network[];
  https?: string;
  wss?: string;
}

interface NodeType {
  id: number;
  name: string;
  type: string;
  version: string;
  created_at: string;
  updated_at: string;
}

interface Node {
  type: string;
  version: string;
  id: number;
  network_id: number;
  chain_id: number;
  name?: string;
  wss: string;
  https: string;
  node_type: NodeType;
  created_at: string;
  updated_at: string;
}

interface NetworkApi {
  type: string;
  version: string;
  id: number;
  chain_id: number;
  name: string;
  nodes: Node[];
  created_at: string;
  updated_at: string;
}

interface ChainApi {
  id: number;
  name: string;
  image_url: string;
  networks: NetworkApi[];
  type: string;
  version: string;
  created_at: string;
}

const getChains = async (): Promise<AxiosResponse<Chain[]>> => {
  return await marlyApi
    .get("/v3/chains", {
      withCredentials: true,
    })
    .then((backend) => {
      // Sort the chains by name alphabetically before mapping
      const sortedChains = backend.data.sort((a: ChainApi, b: ChainApi) =>
        a.name.localeCompare(b.name),
      );

      // Map the sorted data to the desired structure
      backend.data = sortedChains.map((chain: ChainApi) => {
        return {
          id: chain.id,
          name: chain.name,
          imageUrl: chain.image_url,
          networks: chain.networks.map((network) => ({
            id: network.id,
            name: network.name,
            nodeType: network.nodes[0].node_type.name,
            https: network.nodes[0].https,
            wss: network.nodes[0].wss,
            hostSlug: translateHostIfDev(
              new URL(network.nodes[0].https).hostname,
            ),
          })),
          https: chain.networks[0].nodes[0].https,
          wss: chain.networks[0].nodes[0].wss,
        };
      });
      return backend;
    })
    .catch((error) => {
      console.error("Unknown error", error);
      throw error;
    });
};

export default {
  getChains,
};
