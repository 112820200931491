import { FC } from "react";
import { Box, LoadingOverlay } from "@mantine/core";
import { theme } from "src/ui/theme";

export interface LoadingProps {
  isLoading: boolean;
  children: React.ReactNode;
  size?: "xs" | "sm" | "md" | "lg" | "xl";
}

export const Loading: FC<LoadingProps> = ({
  isLoading,
  children,
  size = "xl",
}) => {
  return (
    <Box pos="relative">
      <LoadingOverlay
        data-testid="loading"
        visible={isLoading}
        zIndex={100}
        overlayProps={{ radius: "sm", blur: 2 }}
        loaderProps={{
          color: theme.colors.green[7],
          size,
          type: "bars",
        }}
      />
      {children}
    </Box>
  );
};
