import { allStepsInInput } from "./date";
import { AnalyticsV4Input, AnalyticsV4Row, Interval } from "src/api/user";

export const chartColors = [
  "blue.6",
  "cyan.6",
  "red.6",
  "green.6",
  "lime.6",
  "yellow.6",
  "orange.6",
  "teal.6",
  "grape.6",
  "violet.6",
];

export const hashStringToColor = (str: string): string => {
  let hash = 1091;
  for (let i = 0; i < str.length; i++) {
    hash = (hash * 33) ^ str.charCodeAt(i);
  }
  const index = Math.abs(hash) % chartColors.length;
  return chartColors[index];
};

export type Chart = {
  data: Record<
    string, // Date
    {
      requests: number;
      responses: number;
      byHost: {
        host: string;
        hostName: string;
        requests: number;
        responses: number;
      }[];
    }
  >;
  interval: Interval;
  intervalSteps: Date[];
  methods: Record<
    string, // Method
    {
      requests: number;
      responses: number;
      byHost: {
        host: string;
        hostName: string;
        requests: number;
        responses: number;
      }[];
    }
  >;
  summary: {
    today: number;
    lastSevenDays: number;
    lastThirtyDays: number;
    averageThirtyDays: number;
  };
  isEmpty: boolean;
};

export const initChart = (analyticsInput: AnalyticsV4Input): Chart => {
  const allSteps = allStepsInInput(
    analyticsInput.interval,
    analyticsInput.startTime.toISOString(),
    analyticsInput.endTime.toISOString(),
  );
  const chart: Chart = {
    data: {},
    interval: analyticsInput.interval,
    intervalSteps: allSteps,
    methods: {},
    summary: {
      today: 0,
      lastSevenDays: 0,
      lastThirtyDays: 0,
      averageThirtyDays: 0,
    },
    isEmpty: true,
  };
  for (const date of allSteps) {
    const key = date.toISOString();
    chart.data[key] = {
      responses: 0,
      requests: 0,
      byHost: [],
    };
  }
  return chart;
};

const populateSummary = (chart: Chart) => {
  for (let i = 0; i < chart.intervalSteps.length; i++) {
    const key = chart.intervalSteps[i].toISOString();
    const responses = chart.data[key].responses;

    if (i === 0) {
      chart.summary.today += responses;
    }
    if (i < 7) {
      chart.summary.lastSevenDays += responses;
    }
    if (i < 30) {
      chart.summary.lastThirtyDays += responses;
    }
  }
};

export const populateV4Analytics = (
  chart: Chart,
  data: AnalyticsV4Row[],
  lookupHostName: Map<string, string>,
  filterApiKeys: string[] | undefined = undefined,
  filterHosts: string[] | undefined = undefined,
): Chart => {
  const hosts: Record<
    string, // apiKey
    Record<
      string, // row.domain (host)
      {
        host: string;
        hostName: string;
        requests: number;
        responses: number;
      }
    >
  > = {};

  const methods: Record<
    string, // method
    Record<
      string, // row.domain (host)
      {
        host: string;
        hostName: string;
        requests: number;
        responses: number;
      }
    >
  > = {};

  for (const row of data) {
    if (filterApiKeys && !filterApiKeys.includes(row.apiKey)) {
      continue;
    }
    if (filterHosts && !filterHosts.includes(row.hostSlug)) {
      continue;
    }

    const key: string = new Date(row.startTime).toISOString();

    if (key in chart.data) {
      // byMethod
      const method = row.method;
      if (!chart.methods[method]) {
        chart.methods[method] = {
          responses: 0,
          requests: 0,
          byHost: [],
        };
      }
      chart.methods[method].requests += row.requests;
      chart.methods[method].responses += row.responses;

      if (!methods[method]) {
        methods[method] = {};
      }

      if (!methods[method][row.domain]) {
        methods[method][row.hostSlug] = {
          host: row.domain,
          hostName:
            lookupHostName.get(row.hostSlug) || row.domain.split(".")[0],
          requests: row.requests,
          responses: row.responses,
        };
      } else {
        methods[method][row.domain].requests += row.requests;
        methods[method][row.domain].responses += row.responses;
      }

      chart.methods[method].byHost = Object.values(methods[method]);

      chart.data[key].requests += row.requests;
      chart.data[key].responses += row.responses;

      if (!hosts[key]) {
        hosts[key] = {};
      }

      if (!hosts[key][row.domain]) {
        hosts[key][row.domain] = {
          host: row.domain,
          hostName:
            lookupHostName.get(row.hostSlug) || row.domain.split(".")[0],
          requests: row.requests,
          responses: row.responses,
        };
      } else {
        hosts[key][row.domain].requests += row.requests;
        hosts[key][row.domain].responses += row.responses;
      }
      chart.data[key].byHost = Object.values(hosts[key]);

      // We have added some data to the chart, it is no longer empty
      chart.isEmpty = false;
    }
  }
  populateSummary(chart);
  return chart;
};

export const dayKeySort = (
  a: { name: string },
  b: { name: string },
): number => {
  const [aDay, aMonth] = a.name.split("/");
  const [bDay, bMonth] = b.name.split("/");
  if (aMonth === bMonth) {
    return Number(aDay) - Number(bDay);
  }
  return Number(aMonth) - Number(bMonth);
};
