import { FC } from "react";
import * as classes from "./node-type-badge.module.css";
import { Badge } from "@mantine/core";

interface NodeTypeBadgeProps {
  label: string;
}

export const NodeTypeBadge: FC<NodeTypeBadgeProps> = ({ label }) => {
  return (
    <Badge
      variant="outline"
      color="primaryText"
      classNames={{
        root: classes.badge_nodetype,
      }}
    >
      {label}
    </Badge>
  );
};
