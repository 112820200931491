import { AxiosResponse } from "axios";
import { marlyApi } from "./marly";
import { recursiveToCamelCase } from "src/utility/recursive-to-camel-case";
import { translateHostIfDev } from "src/utility/translate-host-if-dev";
import { Interval } from "./user";

export interface OutsetaAddress {
  uid: string | undefined;
  addressLine1: string | undefined;
  addressLine2: string | undefined;
  addressLine3: string | undefined;
  city: string | undefined;
  state: string | undefined;
  postalCode: string | undefined;
  country: string | undefined;
  created: string | undefined;
  updated: string | undefined;
}

export interface OutsetaAccount {
  uid: string;
  name: string | undefined;
  usecase: string | undefined;
  otherUsecase: string | undefined;
  referrer: string | undefined;
  taxId: string | undefined;
  taxIdType: string | undefined;
  billingAddress: OutsetaAddress | undefined;
}

export interface AnalyticsV4MonthlySummary {
  totalResponses: number;
  totalRequests: number;
}

export interface AnalyticsV4InputWihtoutFilter {
  startTime: Date;
  endTime: Date;
  interval: Interval;
}

export interface AnalyticsV4UsageSummaryRow {
  apiKey: string;
  domain: string;
  hostSlug: string; // host in prod but in dev we translate: "api-kusama.n.dwellir.tech" -> "api-kusama"
}

const getAnalyticsUsageSummary = async (
  input: AnalyticsV4InputWihtoutFilter,
): Promise<Promise<AxiosResponse<AnalyticsV4UsageSummaryRow[]>>> => {
  return await marlyApi
    .post(
      "/v4/organization/analytics/usage-summary",
      {
        start_time: input.startTime.toISOString(), //eslint-disable-line camelcase
        end_time: input.endTime.toISOString(), //eslint-disable-line camelcase
        interval: input.interval,
      },
      {
        withCredentials: true,
      },
    )
    .then((response) => {
      response.data = recursiveToCamelCase(response.data);
      response.data = response.data.map((row: AnalyticsV4UsageSummaryRow) => {
        return Object.assign(row, { hostSlug: translateHostIfDev(row.domain) });
      });
      return response;
    })
    .catch((error) => {
      // Unknown error
      console.error("Unknown error", error);
      throw error;
    });
};

const getOutsetaAccount = async (): Promise<AxiosResponse<OutsetaAccount>> => {
  return await marlyApi
    .get("/v4/organization/information/outseta", {
      withCredentials: true,
    })
    .then((response) => {
      response.data = recursiveToCamelCase(response.data);
      return response;
    })
    .catch((error) => {
      // Unknown error
      console.error("Unknown error", error);
      throw error;
    });
};

const updateOutsetaAccount = async (
  update: OutsetaAccount,
): Promise<AxiosResponse<OutsetaAccount>> => {
  return await marlyApi
    .put(
      "/v4/organization/information/outseta",
      {
        uid: update.uid,
        name: update.name,
        usecase: update.usecase,
        other_usecase: update.otherUsecase, // eslint-disable-line camelcase
        referrer: update.referrer,
        tax_id: update.taxId, // eslint-disable-line camelcase
        tax_id_type: update.taxIdType, // eslint-disable-line camelcase

        // eslint-disable-next-line camelcase
        billing_address: {
          address_line1: update.billingAddress?.addressLine1, // eslint-disable-line camelcase
          address_line2: update.billingAddress?.addressLine2, // eslint-disable-line camelcase
          address_line3: update.billingAddress?.addressLine3, // eslint-disable-line camelcase
          city: update.billingAddress?.city,
          state: update.billingAddress?.state,
          postal_code: update.billingAddress?.postalCode, // eslint-disable-line camelcase
          country: update.billingAddress?.country,
          uid: update.billingAddress?.uid,
          created: update.billingAddress?.created,
          updated: update.billingAddress?.updated,
        },
      },
      {
        withCredentials: true,
      },
    )
    .then((response) => {
      response.data = recursiveToCamelCase(response.data);
      return response;
    })
    .catch((error) => {
      // Unknown error
      console.error("Unknown error", error);
      throw error;
    });
};

const getMonthlySummary = async (): Promise<
  AxiosResponse<AnalyticsV4MonthlySummary>
> => {
  return await marlyApi
    .get("/v4/organization/analytics/monthly_summary", {
      withCredentials: true,
    })
    .then((response) => {
      response.data = recursiveToCamelCase(response.data);
      return response;
    })
    .catch((error) => {
      // Unknown error
      console.error("Unknown error", error);
      throw error;
    });
};

export default {
  getAnalyticsUsageSummary,
  getOutsetaAccount,
  updateOutsetaAccount,
  getMonthlySummary,
};
