import { FC, useEffect, useRef, useState } from "react";
import { Box, Flex, Paper, ScrollArea, StyleProp, Text } from "@mantine/core";
import { ApiKey } from "src/api/api_keys";
import { AnalyticsV4Row } from "src/api/user";
import { ALL_KEYS } from "src/ui/components/select-api-key";
import { RequestState } from "src/store/core/request_state";
import { Loading } from "src/ui/components/loading";
import { useRefDimensions } from "src/utility/use-ref-dimensions";
import useNumberFormatter from "src/utility/number-format";

export interface FunctionCallsCardProps {
  apiKey: ApiKey | null;
  analytics: RequestState<AnalyticsV4Row[]>;
  w?: StyleProp<React.CSSProperties["width"]>;
}

interface Data {
  name: string;
  value: number;
}

interface DataResponsesRequests {
  name: string;
  requests: number;
  responses: number;
}

export const UsageFunctionCalls: FC<FunctionCallsCardProps> = ({
  apiKey,
  analytics,
  w = undefined,
}) => {
  const paperRef = useRef<HTMLDivElement>(null);
  // Bogus data gives nicer initial loading state
  const [data, setData] = useState<Data[]>([
    { name: "Kalle", value: 1 },
    { name: "Anka", value: 1 },
  ]);

  const boxRef = useRef<HTMLDivElement>(null);
  const dimensions = useRefDimensions(boxRef);
  const [scrollHeight, setScrollHeight] = useState<number>(0);
  const scrollbarSize = 10;
  const formatNumber = useNumberFormatter();

  useEffect(() => {
    if (dimensions.height) {
      setScrollHeight(dimensions.height * 0.9);
    }
  }, [dimensions.height]);

  useEffect(() => {
    if (analytics.state === "fulfilled") {
      let filterFn = (_row: AnalyticsV4Row) => true;
      if (apiKey && apiKey.id !== ALL_KEYS.id) {
        filterFn = (row: AnalyticsV4Row) => row.apiKey === apiKey.apiKey;
      }

      const methods = analytics.data
        .filter(filterFn)
        .filter((row) => row.method !== "")
        .reduce((acc: Record<string, DataResponsesRequests>, row) => {
          const name = row.method;
          if (name in acc) {
            acc[name].requests += row.requests;
            acc[name].responses += row.responses;
          } else {
            acc[name] = {
              name,
              requests: row.requests,
              responses: row.responses,
            };
          }
          return acc;
        }, {});

      // Temporary fix for the WS method tracking issue.
      // If responses is 0 show requests instead.
      // Basically the issue is that the rows for responses and requests
      // in WS data is split into 2 lines. For the requests the method is
      // known but for responses it is an empty string
      const filteredMethod = Object.values(methods).map((row) => ({
        name: row.name,
        value: row.responses === 0 ? row.requests : row.responses,
      }));

      const sorted = filteredMethod.sort((a, b) => b.value - a.value);
      setData(sorted);
    }
  }, [apiKey, analytics]);

  return (
    <Paper p="xl" ref={paperRef} radius="md" w={w} bg="second-background">
      <Flex direction="column" h="100%" w="100%" gap="sm">
        <Flex direction="row" w="100%">
          <Text fw={700}>Method</Text>
          <Flex w="100%" justify="flex-end">
            <Text fw={700} pr={scrollbarSize}>
              Responses
            </Text>
          </Flex>
        </Flex>
        <Box ref={boxRef} h="100%">
          <ScrollArea.Autosize
            mah={scrollHeight}
            offsetScrollbars
            scrollbarSize={scrollbarSize}
          >
            <Loading isLoading={analytics.state === "loading"}>
              {data.map((d, index) => (
                <Flex
                  key={`${d.name}-${index}`}
                  direction="row"
                  justify="space-between"
                >
                  <Text fw={400}>{d.name}</Text>
                  <Text fw={700}>{formatNumber(d.value)}</Text>
                </Flex>
              ))}
            </Loading>
          </ScrollArea.Autosize>
        </Box>
      </Flex>
    </Paper>
  );
};
