import { FC } from "react";
import { BaseIconProps } from "./base/icon";

export const ChevronDownIcon: FC<BaseIconProps> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="none"
      stroke={props.fillColor ?? "currentColor"}
      strokeLinecap="round"
      strokeLinejoin="round"
      width={props.w ?? "48"}
      height={props.h ?? "48"}
      strokeWidth="2"
    >
      <path d="M6 9l6 6l6 -6"></path>
    </svg>
  );
};
