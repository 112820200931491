import { t } from "i18next";

export const enum PageRoute {
  INDEX = "/",
  LOGIN = "/login",
  LOGOUT = "/logout",
  DASHBOARD = "/dashboard",
  SUPPORT = "/support",
  REGISTER = "/register",
  ENDPOINTS = "/endpoints",
  API_KEYS = "/api-keys",
  VERIFY = "/verify",
  ACCESS = "/access",
  REQUIRED_DATA = "/required",
  DEDICATED_NODES = "/dedicated-nodes",
  USAGE = "/usage",
  LOGS = "/logs",
}

export function capitalizeFirstLetter(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export const getHeaderBasedOnRoute = (currentRoute: string) => {
  switch (currentRoute) {
    case PageRoute.DASHBOARD:
      return t("root_panel.header.dashboard");
    case PageRoute.REGISTER:
      return t("root_panel.header.register");
    case PageRoute.API_KEYS:
      return t("root_panel.header.api_keys");
    case PageRoute.VERIFY:
      return t("root_panel.header.verify");
    case PageRoute.DEDICATED_NODES:
      return t("root_panel.header.dedicated_nodes");
    case PageRoute.USAGE:
      return t("root_panel.header.usage");
    case PageRoute.SUPPORT:
      return t("root_panel.header.support");
    case PageRoute.LOGS:
      return t("root_panel.header.logs");
    default:
      return t("root_panel.header.endpoints");
  }
};
