import { useEffect } from "react";
import {
  EMPTY_STATE,
  Error,
  LOADING_STATE,
  RequestState,
  fulfilledState,
  rejectedState,
} from "./core/request_state";
import { useAppDispatch, useAppSelector } from "src/core/hooks";
import { RootState } from "src/store";
import organizationAPI, {
  AnalyticsV4MonthlySummary,
  AnalyticsV4UsageSummaryRow,
  OutsetaAccount,
  AnalyticsV4InputWihtoutFilter,
} from "src/api/organization";
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export interface OrganizationState {
  outsetaAccount: RequestState<OutsetaAccount>;
  outsetaAccountGet: RequestState<null>;
  outsetaAccountUpdate: RequestState<null>;
  montlySummary: RequestState<AnalyticsV4MonthlySummary>;
  usageSummary: RequestState<AnalyticsV4UsageSummaryRow[]>;
}

const initialState: OrganizationState = {
  outsetaAccount: EMPTY_STATE,
  outsetaAccountGet: EMPTY_STATE,
  outsetaAccountUpdate: EMPTY_STATE,
  montlySummary: EMPTY_STATE,
  usageSummary: EMPTY_STATE,
};

export const getUsageSummary = createAsyncThunk(
  "user/getUsageSummary",
  async (input: AnalyticsV4InputWihtoutFilter, { rejectWithValue }) => {
    try {
      const request = organizationAPI.getAnalyticsUsageSummary(input);
      const response = await request;
      return response.data; // .data;
    } catch (error: unknown) {
      // Unknown error
      console.error("Unkwon error", error);
      return rejectWithValue({ type: "unknown", serverMessage: "" });
    }
  },
);

export const getOutsetaAccount = createAsyncThunk(
  "user/getOutsetaAccount",
  async (_, { rejectWithValue }) => {
    try {
      const request = organizationAPI.getOutsetaAccount();
      const response = await request;
      return response.data;
    } catch (error: unknown) {
      // Unknown error
      console.error("Unkwon error", error);
      return rejectWithValue({ type: "unknown", serverMessage: "" });
    }
  },
);

export const updateOutsetaAccount = createAsyncThunk(
  "user/updateOutsetaAccount",
  async (input: OutsetaAccount, { rejectWithValue }) => {
    try {
      const response = await organizationAPI.updateOutsetaAccount(input);
      return response.data;
    } catch (error: unknown) {
      // Unknown error
      console.error("Unkwon error", error);
      return rejectWithValue({ type: "unknown", serverMessage: "" });
    }
  },
);

export const getMonthlySummary = createAsyncThunk(
  "user/getMonthlySummary",
  async (_: void, { rejectWithValue }) => {
    try {
      const response = await organizationAPI.getMonthlySummary();
      return response.data;
    } catch (error: unknown) {
      // Unknown error
      console.error("Unkwon error", error);
      return rejectWithValue({ type: "unknown", serverMessage: "" });
    }
  },
);

export const informationSlice = createSlice({
  name: "OrganizationInformation",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Get Usage Summary
    builder.addCase(getUsageSummary.pending, (state, _action) => {
      state.usageSummary = LOADING_STATE;
    });
    builder.addCase(getUsageSummary.rejected, (state, action) => {
      const error = action.payload as Error;

      state.usageSummary = rejectedState(error);
    });
    builder.addCase(
      getUsageSummary.fulfilled,
      (state, action: PayloadAction<AnalyticsV4UsageSummaryRow[]>) => {
        state.usageSummary = fulfilledState(action.payload);
      },
    );

    // Get Outseta Account
    builder.addCase(getOutsetaAccount.pending, (state, _action) => {
      state.outsetaAccountGet = LOADING_STATE;
    });
    builder.addCase(getOutsetaAccount.rejected, (state, action) => {
      const error = action.payload as Error;

      state.outsetaAccountGet = rejectedState(error);
    });
    builder.addCase(
      getOutsetaAccount.fulfilled,
      (state, action: PayloadAction<OutsetaAccount>) => {
        state.outsetaAccount = fulfilledState(action.payload);
        state.outsetaAccountGet = fulfilledState(null);
      },
    );

    // Update OutsetaAccount
    builder.addCase(
      updateOutsetaAccount.pending,
      (state: OrganizationState, _action: PayloadAction<unknown>) => {
        state.outsetaAccountUpdate = LOADING_STATE;
      },
    );
    builder.addCase(
      updateOutsetaAccount.rejected,
      (state: OrganizationState, action: PayloadAction<unknown>) => {
        const error = action.payload as Error;

        state.outsetaAccountUpdate = rejectedState(error);
      },
    );
    builder.addCase(
      updateOutsetaAccount.fulfilled,
      (state, action: PayloadAction<OutsetaAccount>) => {
        state.outsetaAccount = fulfilledState(action.payload);
        state.outsetaAccountUpdate = fulfilledState(null);
      },
    );

    // Get Monthly Summary
    builder.addCase(
      getMonthlySummary.pending,
      (state: OrganizationState, _action: PayloadAction<unknown>) => {
        state.montlySummary = LOADING_STATE;
      },
    );
    builder.addCase(
      getMonthlySummary.rejected,
      (state: OrganizationState, action: PayloadAction<unknown>) => {
        const error = action.payload as Error;

        state.montlySummary = rejectedState(error);
      },
    );
    builder.addCase(
      getMonthlySummary.fulfilled,
      (state, action: PayloadAction<AnalyticsV4MonthlySummary>) => {
        state.montlySummary = fulfilledState(action.payload);
      },
    );
  },
});

// Action creators are generated for each case reducer function
// export const { login } = authSlice.actions;

/* Selectors */
const selectState = (state: RootState): OrganizationState => state.organization;
export const selectInformationUpdateState = (
  state: RootState,
): RequestState<null> => state.organization.update;
export const selectOutsetaAccountUpdateState = (
  state: RootState,
): RequestState<null> => state.organization.outsetaAccountUpdate;

export const useUsageSummary = (
  input: AnalyticsV4InputWihtoutFilter,
): RequestState<AnalyticsV4UsageSummaryRow[]> => {
  const dispatch = useAppDispatch();
  const state = useAppSelector(selectState);

  useEffect(() => {
    if (state.usageSummary == EMPTY_STATE) {
      dispatch(getUsageSummary(input));
    }
  }, [state, dispatch, input]);

  return state.usageSummary;
};

export const useOutsetaAccount = (): RequestState<OutsetaAccount> => {
  const dispatch = useAppDispatch();
  const state = useAppSelector(selectState);

  useEffect(() => {
    if (
      state.outsetaAccount == EMPTY_STATE &&
      state.outsetaAccountGet === EMPTY_STATE
    ) {
      dispatch(getOutsetaAccount());
    }
  }, [state, dispatch]);

  // TODO We should probably handle errors in a generic way?
  // Throw here and have a error boundary that handles it?

  return state.outsetaAccount;
};

export const useAnalyticsV4MonthlySummary =
  (): RequestState<AnalyticsV4MonthlySummary> => {
    const dispatch = useAppDispatch();
    const state = useAppSelector(selectState);

    useEffect(() => {
      if (state.montlySummary == EMPTY_STATE) {
        dispatch(getMonthlySummary());
      }
    }, [state, dispatch]);

    return state.montlySummary;
  };

// export const { } = informationSlice.actions;
export default informationSlice.reducer;
