// Translates "api-kusama.n.dwellir.tech" -> "api-kusama"
// The setup in localhost/staging is not the same as in production
// This causes the Chain list domains, that is synced from the Dwellir Chain
// Operations, to not match what is actually reported in the analytics unless
// we do this translation.
export const translateHostIfDev = (
  host: string,
  { origin }: { origin?: string } = {},
) => {
  let checkOrigin = origin;
  if (checkOrigin == null) {
    checkOrigin = window.location.origin;
  }
  const isProd = checkOrigin === "https://dashboard.dwellir.com";
  if (isProd) {
    return host;
  }
  return host.split(".")[0];
};
