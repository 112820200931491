import { FC } from "react";
import { Heading } from "../dashboard";
import { UsageTextRow } from "./usage-text-row";
import {
  Box,
  Center,
  Flex,
  Paper,
  Space,
  StyleProp,
  Text,
} from "@mantine/core";
import { AnalyticsV4Input, CurrentSubscription } from "src/api/user";
import { useRpsAnalytics } from "src/store/user";
import { Loading } from "src/ui/components/loading";
import { theme } from "src/ui/theme";
import { useTranslation } from "react-i18next";
import { RequestState } from "src/store/core/request_state";

export interface UsageRpsProps {
  subscription: RequestState<CurrentSubscription>;
  w?: StyleProp<React.CSSProperties["width"]>;
  input: AnalyticsV4Input;
}

export const UsageRps: FC<UsageRpsProps> = ({ subscription, w, input }) => {
  // This is very similar to the dashboard bottom card, might be reasonable
  // to re-use that as much as possible
  // When working on this part, move it into the components directory
  const rpsAnalytics = useRpsAnalytics(input);

  const { t } = useTranslation();

  return (
    <Paper p="xl" radius="md" w={w} bg="second-background">
      <Flex direction="column" gap="md">
        <Text fw={700} size="lg">
          Response per second (Current billing cycle)
        </Text>
        <Loading isLoading={rpsAnalytics.state === "loading"}>
          <Flex direction="column" gap="md">
            <UsageTextRow
              labels={["AVERAGE", "RPS"]}
              value={
                rpsAnalytics.state === "fulfilled"
                  ? rpsAnalytics.data.rps > 1
                    ? Math.round(rpsAnalytics.data.rps)
                    : rpsAnalytics.data.rps
                  : 0
              }
              valueColor="green"
            />
            <UsageTextRow
              labels={["PEAK", "RPS"]}
              value={
                rpsAnalytics.state === "fulfilled"
                  ? rpsAnalytics.data.peakRps
                  : 0
              }
              compact
              valueColor={theme.colors.yellow[3]}
            />
            <UsageTextRow
              labels={["LIMITED", "RESPONSES"]}
              value={
                rpsAnalytics.state === "fulfilled"
                  ? rpsAnalytics.data.limitedRequests
                  : 0
              }
              valueColor={theme.colors.red[7]}
            />
          </Flex>
          <Space h="xl" />
          <Flex direction="column" visibleFrom="sm">
            <Box pb="8px">
              <Heading label="Your Plan's RPS Limits" />
            </Box>
            <Flex direction="row" gap="md" justify="center">
              <Flex direction="column">
                <Box pb="4px">
                  <Center>
                    <Loading isLoading={subscription.state === "loading"}>
                      <Text size="calc(2.5rem * var(--mantine-scale))" fw={700}>
                        {subscription.state === "fulfilled"
                          ? t("format.number", {
                              number: subscription.data.rateLimit ?? "-",
                            })
                          : "-"}
                      </Text>
                    </Loading>
                  </Center>
                </Box>
                <Text size="lg" fw={600}>
                  RATE LIMIT
                </Text>
              </Flex>

              <Flex direction="column">
                <Box pb="4px">
                  <Center>
                    <Loading isLoading={subscription.state === "loading"}>
                      <Text size="calc(2.5rem * var(--mantine-scale))" fw={700}>
                        {subscription.state === "fulfilled"
                          ? t("format.number", {
                              number: subscription.data.burstLimit ?? "-",
                            })
                          : "-"}
                      </Text>
                    </Loading>
                  </Center>
                </Box>
                <Text size="lg" fw={600}>
                  BURST LIMIT
                </Text>
              </Flex>
            </Flex>
          </Flex>
        </Loading>
      </Flex>
    </Paper>
  );
};
