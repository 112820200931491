import { FC } from "react";
import { BaseIconProps, Icon } from "./base/icon";

export const ProfileIcon: FC<BaseIconProps> = (props) => {
  return (
    <Icon width={"16"} height={"16"} viewBox="0 -100 1024 1024" {...props}>
      <path d="M480 64A416.64 416.64 0 0 0 64 480 416.64 416.64 0 0 0 480 896 416.64 416.64 0 0 0 896 480 416.64 416.64 0 0 0 480 64z m0 64C674.752 128 832 285.248 832 480a351.36 351.36 0 0 1-81.024 225.024 289.408 289.408 0 0 0-162.944-171.776A159.36 159.36 0 0 0 640 416C640 328 568 256 480 256A160.448 160.448 0 0 0 320 416c0 46.272 20.224 88 52.032 117.248a289.024 289.024 0 0 0-162.752 171.776A350.208 350.208 0 0 1 128 480C128 285.248 285.248 128 480 128z m0 192C533.504 320 576 362.496 576 416S533.504 512 480 512A95.36 95.36 0 0 1 384 416C384 362.496 426.496 320 480 320z m0 256c108.8 0 198.016 77.248 218.752 179.776A350.528 350.528 0 0 1 480 832a349.248 349.248 0 0 1-218.496-76.224A222.72 222.72 0 0 1 480 576z" />
    </Icon>
  );
};
