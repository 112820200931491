import { Chain } from "src/api/chain";
import { AnalyticsV4Row } from "src/api/user";

export const filterChainsWithData = (
  chains: Chain[],
  analytics: AnalyticsV4Row[],
): Chain[] => {
  const hostsWithData = new Set(analytics.map((row) => row.hostSlug));
  const chainsWithData = chains.filter((chain) => {
    return chain.networks.some((network) =>
      hostsWithData.has(network.hostSlug),
    );
  });
  return chainsWithData;
};
