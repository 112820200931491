import { FC, ReactNode, useEffect, useState } from "react";
import copy from "copy-to-clipboard";
import * as classes from "./logs-table.module.css";
import {
  ActionIcon,
  alpha,
  Box,
  Center,
  Flex,
  Image,
  Menu,
  Paper,
  Space,
  Table,
  Text,
} from "@mantine/core";
import { theme } from "src/ui/theme";
import { CopyIcon } from "src/ui/icons/copy-icon";
import { ALL_LOGS, LogClass } from "src/ui/components/select-log-class";
import { useTranslation } from "react-i18next";
import { ChevronDownIcon } from "src/ui/icons/chevron-down-icon";
import { ChevronUpIcon } from "src/ui/icons/chevron-up-icon";
import { Chain } from "src/api/chain";
import { RequestState } from "src/store/core/request_state";
import { Loading } from "src/ui/components/loading";
import { translateHostIfDev } from "src/utility/translate-host-if-dev";
import { NodeTypeBadge } from "src/ui/components/node-type-badge/node-type-badge";

interface RequestResponseInfo {
  timestamp?: string;
  headers?: Record<string, string | undefined>;
  body?: string;
}

interface RequestInfo extends RequestResponseInfo {
  method?: string;
  rpcMethod?: string;
  url?: string;
}

interface ResponseInfo extends RequestResponseInfo {
  statusCode: number;
  statusLabel: string;
}

export interface LogRow {
  date: Date;
  apiKey: string;
  domain: string;
  request: RequestInfo;
  response: ResponseInfo;
}

interface LogsTableProps {
  chains: RequestState<Chain[]>;
  logs: LogRow[];
  logClass: LogClass;
}

interface RenderRequestResponseInfo extends RequestResponseInfo {
  label: "Request" | "Response";
  method?: string;
  url?: string;
}
const RenderRequestResponseInfo: FC<RenderRequestResponseInfo> = ({
  label,
  url,
  method,
  headers,
  body,
}) => {
  const headersCopy =
    headers != null &&
    Object.entries(headers).reduce((acc, [h, v]) => acc + `${h}: ${v}\n`, "");
  const bodyCopy = body;

  let curlCopy = null;
  if (method && url && url.startsWith("https://")) {
    const curlHeaders = Object.entries(headers ?? []).reduce(
      (acc, [h, v]) => acc + `-H '${h}=${v}'`,
      "",
    );
    curlCopy = `curl '${url}' -X ${method} ${curlHeaders} --data-raw '${bodyCopy}'`;
  }

  const handleCopyClick = (data: string) => {
    copy(data);
  };

  const MenuItem: FC<{ label: string; data: string }> = ({ label, data }) => (
    <Menu.Item onClick={() => handleCopyClick(data)}>
      <Flex direction="row" justify="space-between">
        <Text>{label}</Text>
        <Center>
          <CopyIcon h={12} w={12} />
        </Center>
      </Flex>
    </Menu.Item>
  );

  return (
    <Paper classNames={{ root: classes["logs-data-box"] }} radius="md">
      <Flex
        classNames={{ root: classes["request-response-info"] }}
        direction="row"
        justify="space-between"
        p="sm"
      >
        <Text fw={700} c="green">
          {label}
        </Text>
        <Menu
          trigger="click-hover"
          position="bottom-end"
          arrowPosition="center"
          offset={0}
          withArrow
        >
          <Menu.Target>
            <ActionIcon variant="transparent">
              <CopyIcon fillColor={theme.colors.green[6]} h={18} w={18} />
            </ActionIcon>
          </Menu.Target>
          <Menu.Dropdown w={200}>
            {headersCopy && <MenuItem label="Headers" data={headersCopy} />}
            {bodyCopy && <MenuItem label="Body" data={bodyCopy} />}
            {headersCopy && bodyCopy && (
              <MenuItem
                label="Headers & Body"
                data={`${headersCopy}\n${body}`}
              />
            )}
            {curlCopy && <MenuItem label="As Curl" data={curlCopy} />}
          </Menu.Dropdown>
        </Menu>
      </Flex>
      <Box p="sm">
        {headers && (
          <Text fw={400} c="green">
            Headers
          </Text>
        )}
        {headers &&
          Object.entries(headers).map(([header, value], index) => (
            <Text key={`${header}-${index}`}>
              {"> "}
              <Text span fw={700}>
                {header}:
              </Text>{" "}
              {value}
            </Text>
          ))}
        <Space h="md" />
        {body && (
          <>
            <Text fw={400} c="green">
              Body
            </Text>
            <Text>{body}</Text>
          </>
        )}
      </Box>
    </Paper>
  );
};

export const LogsTable: FC<LogsTableProps> = ({ chains, logs, logClass }) => {
  const { i18n } = useTranslation();
  const [expandIndex, setExpandIndex] = useState<number | null>(null);

  const [filteredLogs, setFilteredLogs] = useState<LogRow[]>(logs);

  useEffect(() => {
    if (logClass === ALL_LOGS) {
      setFilteredLogs(logs);
    } else {
      setFilteredLogs(
        logs.filter((log) => log.response.statusCode === logClass.id),
      );
    }
  }, [logClass, logs]);

  const handleRowClick = (index: number) => {
    if (expandIndex === index) {
      setExpandIndex(null);
    } else {
      setExpandIndex(index);
    }
  };

  const renderChain = (domain: string) => {
    let chain = undefined;
    if (chains.state === "fulfilled") {
      chain = chains.data.find((c) => {
        if (domain.startsWith("https://")) {
          return c.networks.some(
            (n) =>
              n.https != null &&
              translateHostIfDev(n.https) === translateHostIfDev(domain),
          );
        } else {
          return c.networks.some(
            (n) =>
              n.wss != null &&
              translateHostIfDev(n.wss) === translateHostIfDev(domain),
          );
        }
      });
    }
    let network = undefined;
    if (chain) {
      network = chain.networks.find((n) => {
        if (domain.startsWith("https://")) {
          return (
            n.https != null &&
            translateHostIfDev(n.https) === translateHostIfDev(domain)
          );
        } else {
          return (
            n.wss != null &&
            translateHostIfDev(n.wss) === translateHostIfDev(domain)
          );
        }
      });
    }
    return (
      <Loading isLoading={chains.state === "loading"}>
        {chain && network && (
          <Flex direction="row">
            <Center>
              <Image h={16} w={16} src={chain.imageUrl} mr="xs" />
              <Text mr="xs">{chain.name}</Text>
              <NodeTypeBadge label={network.nodeType} />
            </Center>
          </Flex>
        )}
        {!(chain && network) && <Text>Unknown chain</Text>}
      </Loading>
    );
  };

  const renderRows = (rows: LogRow[]) => {
    const res: ReactNode[] = [];
    for (const [index, log] of rows.entries()) {
      res.push(
        <Table.Tr
          key={`log-${log.date.toISOString()}-${index}`}
          onClick={() => handleRowClick(index)}
          bg={
            expandIndex === index
              ? alpha(theme.colors.green[6], 0.15)
              : undefined
          }
        >
          <Table.Td>
            {log.date.toLocaleString(i18n.language, {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            })}
          </Table.Td>
          <Table.Td>
            {log.date.toLocaleString(i18n.language, {
              timeZone: "UTC",
              hour: "2-digit",
              minute: "2-digit",
              second: "2-digit",
            })}
          </Table.Td>
          <Table.Td>{log.request.rpcMethod}</Table.Td>
          <Table.Td>{log.response.statusCode}</Table.Td>
          <Table.Td>{log.apiKey}</Table.Td>
          <Table.Td>{renderChain(log.domain)}</Table.Td>
          <Table.Td>
            <Flex direction="row" h="100%" align="center">
              {expandIndex === index ? (
                <ChevronUpIcon h={16} w={16} />
              ) : (
                <ChevronDownIcon h={16} w={16} />
              )}
            </Flex>
          </Table.Td>
        </Table.Tr>,
      );
      if (expandIndex === index) {
        res.push(
          <Table.Tr
            classNames={{ tr: classes["logs-tr-expanded"] }}
            key={`log-${log.date.toISOString()}-${index}-expanded`}
          >
            <Table.Td colSpan={7} bg={alpha(theme.colors.green[6], 0.15)}>
              <Flex
                direction="row"
                p="sm"
                w="100%"
                gap="xl"
                justify="space-evenly"
              >
                <RenderRequestResponseInfo
                  label="Request"
                  method={log.request.method}
                  url={log.request.url}
                  headers={log.request.headers}
                  body={log.request.body}
                />
                <RenderRequestResponseInfo
                  label="Response"
                  headers={log.response.headers}
                  body={log.response.body}
                />
              </Flex>
            </Table.Td>
          </Table.Tr>,
        );
      }
    }
    return res;
  };

  return (
    <Table
      classNames={{
        table: classes["logs-table"],
        th: classes["logs-th"],
        td: classes["logs-td"],
        tr: classes["logs-tr"],
      }}
      w="100%"
      horizontalSpacing="xl"
      withTableBorder
      withRowBorders
    >
      <Table.Thead>
        <Table.Tr>
          <Table.Th>Date</Table.Th>
          <Table.Th>Time (UTC)</Table.Th>
          <Table.Th>RPC Method</Table.Th>
          <Table.Th>Response Code</Table.Th>
          <Table.Th>Key</Table.Th>
          <Table.Th>Network</Table.Th>
          <Table.Th></Table.Th>
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>{renderRows(filteredLogs)}</Table.Tbody>
    </Table>
  );
};
