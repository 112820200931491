import { ALL_KEYS } from "../select-api-key";
import { ApiKey } from "src/api/api_keys";
import { Chain } from "src/api/chain";
import { AnalyticsV4UsageSummaryRow } from "src/api/organization";

export type HasDataPartition<T> = {
  hasData: T[];
  missingData: T[];
};

export const chainsHasDataPartition = (
  apiKey: ApiKey | null,
  chains: Chain[],
  usage: AnalyticsV4UsageSummaryRow[],
): HasDataPartition<Chain> => {
  const hostsWithDataForKey = new Set(
    usage.map((row) => {
      if (apiKey == null || apiKey.id === ALL_KEYS.id) {
        return row.hostSlug;
      } else if (row.apiKey === apiKey.apiKey) {
        return row.hostSlug;
      }
      return null;
    }),
  );
  const partition = chains.reduce(
    (acc: HasDataPartition<Chain>, chain: Chain) => {
      if (
        chain.networks.some((network) =>
          hostsWithDataForKey.has(network.hostSlug),
        )
      ) {
        acc.hasData.push(chain);
      } else {
        acc.missingData.push(chain);
      }
      return acc;
    },
    { hasData: [], missingData: [] },
  );
  return partition;
};
