import { FC, useEffect, useState } from "react";
// import { addMonths, startOfMonth } from "date-fns";
import { UsageTextRow } from "./usage-text-row";
import {
  Center,
  Flex,
  Paper,
  Space,
  Stack,
  StyleProp,
  Text,
} from "@mantine/core";
import { CurrentSubscription } from "src/api/user";
// import { useAnalyticsV4 } from "src/store/user";
import { theme } from "src/ui/theme";
import { useTranslation } from "react-i18next";
// import { startOfCurrentMonth } from "src/utility/date";
import { Loading } from "src/ui/components/loading";
import { RequestState } from "src/store/core/request_state";
import { useAnalyticsV4MonthlySummary } from "src/store/organization";
import { MonthlyProgressBar } from "src/ui/components/monthly-progress-bar";

export interface BillingCycleCardProps {
  subscription: RequestState<CurrentSubscription>;
  w?: StyleProp<React.CSSProperties["width"]>;
}

export const UsageBillingCycle: FC<BillingCycleCardProps> = ({
  subscription,
  w,
}) => {
  // We request the analytics separately here because we want it to start at
  // "cycle" beginning. In the future this should be adjusted to the renewal
  // date of the subscription but we don't have that currently.
  // to be used once the useanalyticsv4 data is based on the organization
  // clickhouse tables.
  // const analytics = useAnalyticsV4({
  //   interval: "day",
  //   startTime: startOfCurrentMonth(),
  //   endTime: startOfMonth(addMonths(new Date(), 1)),
  // });
  const montlySummary = useAnalyticsV4MonthlySummary();

  const { t } = useTranslation();

  // Raw numbers
  const [responsesThisCycle, setResponsesThisCycle] = useState<number>(0);
  const [projectedTotal, setProjectedTotal] = useState<number>(0);
  const [projectedOverages, setProjectedOverages] = useState<number>(0);

  // Progress bars (percentages, 0-100)
  const [progressThisCycle, setProgressThisCycle] = useState<
    number | undefined
  >(undefined);
  const [prognosisThisCycle, setPrognosisThisCycle] = useState<
    number | undefined
  >(undefined);

  useEffect(() => {
    if (
      montlySummary.state === "fulfilled" &&
      subscription.state === "fulfilled"
    ) {
      // To be used once the useAnalyticsV4 data is based on the organization
      // clickhouse tables.
      //const responsesThisMonth = analytics.data.reduce(
      //  (acc, row) => row.requests + acc,
      //  0,
      //);
      const responsesThisMonth = montlySummary.data.totalResponses;
      setResponsesThisCycle(responsesThisMonth);

      // Simple linear prognosis, if we want to do something fancier we should
      // move the logic to the backend.
      const daysInMonth = (year: number, month: number) =>
        new Date(year, month, 0).getDate();
      const today = new Date();
      const daysThisMonth = daysInMonth(
        today.getFullYear(),
        today.getMonth() + 1,
      );
      const daysSoFar = today.getDate();
      const daysLeftThisMonth = daysThisMonth - daysSoFar;

      const averageResponsesPerDay = responsesThisMonth / daysSoFar;
      const estimated = averageResponsesPerDay * daysLeftThisMonth;
      const estimatedTotal = responsesThisMonth + estimated;
      setProjectedTotal(estimatedTotal);

      // Progressbar parts
      if (
        subscription.data.monthlyQuota == null ||
        subscription.data.monthlyQuota === 0
      ) {
        setProgressThisCycle(undefined);
      } else {
        const progress =
          (responsesThisMonth / subscription.data.monthlyQuota) * 100;
        setProgressThisCycle(progress);
      }

      if (
        subscription.data.monthlyQuota == null ||
        subscription.data.monthlyQuota === 0
      ) {
        setPrognosisThisCycle(undefined);
      } else {
        setPrognosisThisCycle(
          (estimatedTotal / subscription.data.monthlyQuota) * 100,
        );
      }
    }
  }, [montlySummary, subscription]);

  useEffect(() => {
    if (subscription.state === "fulfilled") {
      if (subscription.data.monthlyQuota === 0) {
        setProjectedOverages(0);
      } else {
        setProjectedOverages(
          Math.max(
            0,
            Math.round(projectedTotal - (subscription.data.monthlyQuota ?? 0)),
          ),
        );
      }
    }
  }, [subscription, projectedTotal]);

  return (
    <Paper p="xl" w={w} radius="md" bg="second-background">
      <Flex direction="column" gap="md">
        <Text fw={700} size="lg">
          Current billing cycle
        </Text>
        <Loading isLoading={montlySummary.state === "loading"}>
          <Flex direction="column" gap="md">
            <UsageTextRow
              labels={["RESPONSES", "SERVED"]}
              value={responsesThisCycle}
              valueColor="green"
            />
            <UsageTextRow
              labels={["PROJECTED", "RESPONSES"]}
              value={projectedTotal}
              compact
              valueColor={
                subscription.state === "fulfilled" &&
                subscription.data.monthlyQuota != 0 &&
                subscription.data.monthlyQuota != null &&
                projectedTotal >= subscription.data.monthlyQuota
                  ? theme.colors.yellow[3]
                  : "primary"
              }
            />
            <UsageTextRow
              labels={["PROJECTED", "OVERAGES"]}
              value={projectedOverages}
              compact
              valueColor={"primary"}
            />
          </Flex>
          <Space h="xl" />
          <Stack>
            <MonthlyProgressBar
              progress={progressThisCycle}
              prognosis={prognosisThisCycle}
            />
            <Stack gap="lg">
              <Center>
                <Loading isLoading={subscription.state === "loading"}>
                  {subscription.state === "fulfilled" &&
                  subscription.data.monthlyQuota != null ? (
                    <Text size="sm" c="dimmed" fw={400}>
                      Your plan includes{" "}
                      {t("format.number", {
                        number: subscription.data.monthlyQuota,
                      })}{" "}
                      Responses
                    </Text>
                  ) : (
                    <Text size="sm" c="dimmed" fw={400}>
                      Your plan is unlimited
                    </Text>
                  )}
                </Loading>
              </Center>
            </Stack>
          </Stack>
        </Loading>
      </Flex>
    </Paper>
  );
};
