import { useEffect, useState } from "react";
import { cookieSelector, isAuthenticated } from "src/store/authentication";
import { Text, Flex, Box } from "@mantine/core";
import { useLocation, useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { PageRoute } from "src/utility/utils";
import { DwellirShield } from "src/ui/icons/dwellir-shield";
import { TextLink } from "src/ui/components/text-link";
import { MaintenanceModal } from "src/ui/components/maintenance-modal";
import { OutsetaLogin } from "src/ui/components/outseta/outseta-login";

export const Login = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [cookies] = useCookies(cookieSelector);
  const [doRedirect, setDoRedirect] = useState(false);

  useEffect(() => {
    if (isAuthenticated(cookies)) {
      setDoRedirect(true);
    }
  }, [cookies]);

  useEffect(() => {
    if (doRedirect) {
      const query = new URLSearchParams(location.search);
      const redirect = query.get("from");
      if (redirect) {
        navigate(redirect);
      } else {
        navigate(PageRoute.INDEX);
      }
    }
  }, [doRedirect, location, navigate]);

  return (
    <Box w="100dvw" h="100dvh" bg="background">
      <MaintenanceModal />
      <Flex h="100dvh" justify="center" align="center" p="sm" gap="xl">
        <Flex direction="column" w={400} gap="lg">
          <Flex direction="row" w="100%" gap="rem(2)" justify="center">
            <DwellirShield />
            <Text size="xl" fw={600}>
              Blockchain API Platform
            </Text>
          </Flex>
          <Flex direction="column" gap="xl">
            <OutsetaLogin />

            <Flex w="100%" justify="center">
              <Text size="xs">
                Don&apos;t have an account?{" "}
                <TextLink target={PageRoute.REGISTER} newTab={false}>
                  Create an account
                </TextLink>
                .
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
};
